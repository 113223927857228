import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  InputGroup,
  Label,
  Row,
  Table,
} from "reactstrap";
import { Trash } from "react-feather";
import { toast } from "react-toastify";
import Select from "react-select";
import DatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import {
  addAddress,
  removeAddress,
  setDefaultAddress,
  updateAddress,
} from "services/api";
import { TableButton } from "components/TableButton";
import { Checkbox } from "components/Checkbox";
import { FormTitle } from "components/FormTitle";
import { MySpinner } from "components/MySpinner";

import { Cut } from "components/Cut";
import classNames from "classnames";
import { useUser } from "../UserContext";
import { customerName } from "utils/customerName";
import { TRANSPORT_COMPANY } from "pages/orderOne";

import "./addresses.scss";

export const ROUTES = [
  { value: 1, label: "Маршрут А: приём заказов до 10:30, погрузка до 11:30", shortName: "А" },
  { value: 2, label: "Маршрут B: приём заказов до 11:00, погрузка до 12:00", shortName: "B" },
  { value: 3, label: "Маршрут С: приём заказов до 11:30, погрузка до 12:30", shortName: "C" },
  { value: 4, label: "Маршрут D: приём заказов до 10:45, погрузка до 11:45", shortName: "D" },
  { value: 5, label: "Маршрут E: приём заказов до 12:30, погрузка до 13:30", shortName: "E" },
  { value: 6, label: "Маршрут B2: приём заказов до 13:00, погрузка до 14:00", shortName: "B2" },
  { value: 7, label: "Маршрут D2: приём заказов до 14:00, погрузка до 15:00", shortName: "D2" },
  { value: 8, label: "Маршрут C2: приём заказов до 18:30, погрузка до 19:30", shortName: "C2" }
];

registerLocale("ru", ru);

export const Addresses = ({ customer }) => {
  const { user } = useUser();
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [id, setId] = useState("");
  const [city, setCity] = useState("");
  const [transportCompany, setTransportCompany] = useState("");
  const [senderIsPayer, setSenderIsPayer] = useState(null);
  const [contact, setContact] = useState("");
  const [comment, setComment] = useState("");
  const [addresses, setAddresses] = useState([]);
  const [showAddAddress, setShowAddAddress] = useState(false);
  const [route, setRoute] = useState("");
  const [role, setRole] = useState("user");
  const [workingHours, setWorkingHours] = useState({});
  const [workingHoursText, setWorkingHoursText] = useState("");

  useEffect(() => {
    setAddresses(customer.addresses || []);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  useEffect(() => {
    if (user.roles?.find((role) => role === "ROLE_ADMIN")) {
      setRole("admin");
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const payers = () => [
    { label: "ЛИДЕР ТРАК", value: true },
    {
      label: customerName(customer),
      value: false,
    },
  ];

  const addAddressHandler = () => {
    setLoading(true);
    addAddress({
      customer: customer.id,
      address,
      city,
      contact,
      comment,
      transportCompany,
      senderIsPayer: senderIsPayer?.value === true ? 1 : 0,
      route: route || null,
      workingHours: `с ${moment(workingHours.from).format("HH:mm")} до ${moment(workingHours.to).format("HH:mm")}`
    })
      .then((res) => {
        setAddress("");
        setTransportCompany(null);
        setCity("");
        setSenderIsPayer(false);
        setContact("");
        setComment("");
        setAddresses(res || []);
        setShowAddAddress(false);
        setRoute("");
        setWorkingHours({});
        toast.success("Адрес успешно добавлен");
      })
      .catch((e) => {
        toast.error(
          e.response?.data?.message || "Ошибка при добавлении адреса"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateAddressHandler = () => {
    if (!id) return;
    setLoading(true);

    console.log(route);

    updateAddress({
      id,
      address,
      city,
      contact,
      comment,
      transportCompany: transportCompany || null,
      senderIsPayer: senderIsPayer?.value === true ? 1 : 0,
      route: route || null,
      workingHours: `с ${moment(workingHours.from).format("HH:mm")} до ${moment(workingHours.to).format("HH:mm")}`
    })
      .then((res) => {
        setId("");
        setAddress("");
        setTransportCompany(null);
        setCity("");
        setSenderIsPayer(false);
        setContact("");
        setComment("");
        setAddresses(res || []);
        setShowAddAddress(false);
        setRoute("");
        setWorkingHours({});
        toast.success("Адрес успешно обновлен");
      })
      .catch((e) => {
        toast.error(
          e.response?.data?.message || "Ошибка при обновлении адреса"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const removeAddressHandler = (id) => {
    setLoading(true);
    removeAddress({ customer: user.id, id })
      .then((res) => {
        setAddresses(res || []);
        toast.success("Адрес успешно удален");
      })
      .catch((e) => {
        toast.error(e.response?.data?.message || "Ошибка при удалении адреса");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const defaultAddressHandler = (val, id) => {
    if (!val) return;
    setLoading(true);
    setDefaultAddress({ id })
      .then((res) => {
        setAddresses(res || []);
        toast.success("Данные успешно обновлены");
      })
      .catch((e) => {
        toast.error(
          e.response?.data?.message || "Ошибка при обновлении данных"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const showAddressHandler = (id) => {
    const address = addresses.find((item) => item.id === id);
    setId(id);
    setAddress(address?.address);
    setTransportCompany(address?.transportCompany);
    setCity(address?.city);
    setRoute(address?.route);
    setSenderIsPayer(
      payers().find((item) => item.value === address?.senderIsPayer)
    );
    setContact(address?.contact);
    setComment(address?.comment);
    setShowAddAddress(true);

    const times = address?.workingHours
      ? address?.workingHours.replace('с ', '').replace(' до ', ' ').split(' ')
      : [];

    if (times.length > 1) {
      if (!moment(times[0], "HH:mm").isValid() || !moment(times[1], "HH:mm").isValid()) return;
      const hours = {
        from: moment(times[0], "HH:mm").toDate(),
        to: moment(times[1], "HH:mm").toDate()
      }
      setWorkingHours(hours);
    }
  };

  const renderAddressesTable = () => {
    return (
      <div className="react-bootstrap-table">
        <Table className={classNames("table table-hover pointer")}>
          <thead>
            <tr>
              <th style={{ width: "8%" }}>Город</th>
              <th style={{ width: "23%" }}>Адрес</th>
              <th style={{ width: "10%" }}>Трансп. компания</th>
              <th style={{ width: "8%" }}>Плат.</th>
              <th style={{ width: "13%" }}>Контакт получателя</th>
              <th style={{ width: "12%" }}>Маршрут отгрузки</th>
              <th style={{ width: "12%" }}>Комментарий к отправке</th>
              <th className="center" style={{ width: "5%" }}>
                Основной
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {addresses.map((item) => (
              <tr key={item.id} onClick={() => showAddressHandler(item.id)}>
                <td>{item.city}</td>
                <td>
                  <Cut>{item.address}</Cut>
                </td>
                <td>
                  <Cut>{item.transportCompany}</Cut>
                </td>
                <td>
                  <Cut>
                    {item.senderIsPayer ? "ЛИДЕР ТРАК" : customerName(customer)}
                  </Cut>
                </td>
                <td>
                  <Cut>{item.contact}</Cut>
                </td>
                <td>
                  <Cut>{ROUTES.find(v => v.value === item.route)?.label}</Cut>
                </td>
                <td>
                  <Cut>{item.comment}</Cut>
                </td>
                <td className="center">
                  <Checkbox
                    id={item.id}
                    value={item.id}
                    checked={item.main || false}
                    onChange={(val) => defaultAddressHandler(val, item.id)}
                  />
                </td>
                <td>
                  <TableButton
                    Icon={Trash}
                    onClick={() => removeAddressHandler(item.id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  };

  return (
    <div className="addresses">
      <Card className="mb-0">
        <CardBody className="pb-2">
          <FormTitle>Адреса доставки</FormTitle>
        </CardBody>
      </Card>

      {loading && <MySpinner overlay />}

      <div className="addresses__table">
        <div className="addresses__header"></div>
        {renderAddressesTable()}
        <div className="addresses__add">
          <Card className="mb-0">
            <CardBody>
              {showAddAddress ? (
                <>
                  <Row>
                    <Col className="elem-field">
                      <Label className="text-sm-right">Город доставки</Label>
                      <Input
                        onChange={(e) => setCity(e.target.value)}
                        value={city}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="elem-field">
                      <Label className="text-sm-right">Адрес доставки</Label>
                      <Input
                        type="textarea"
                        className="addresses__textarea"
                        onChange={(e) => setAddress(e.target.value)}
                        value={address}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="elem-field">
                      <Label className="text-sm-right">Трансп. компания</Label>
                      <Select
                        name="transportCompany"
                        placeholder="Транспортная компания"
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={TRANSPORT_COMPANY}
                        value={TRANSPORT_COMPANY.find(
                          (item) => item.value === transportCompany
                        )}
                        onChange={(val) => setTransportCompany(val?.value)}
                        isClearable
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="elem-field">
                      <Label className="text-sm-right">Плательщик</Label>
                      <Select
                        name="senderIsPayer"
                        placeholder="Плательщик"
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={payers()}
                        value={senderIsPayer}
                        onChange={setSenderIsPayer}
                        isClearable
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="elem-field">
                      <Label className="text-sm-right">
                        Контакты получателя
                      </Label>
                      <Input
                        onChange={(e) => setContact(e.target.value)}
                        value={contact}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="elem-field">
                      <Label className="text-sm-right">Маршрут доставки</Label>
                      <Select
                        name="route"
                        placeholder="Маршрут доставки"
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={ROUTES}
                        value={ROUTES.find(v => v.value === route)}
                        onChange={val => setRoute(val.value)}
                        isClearable
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="elem-field">
                      <Label className="text-sm-right">
                        Время работы
                      </Label>

                      <InputGroup className="daterange">
                        <DatePicker
                          placeholderText="с"
                          selected={workingHours?.from}
                          onChange={(val) => setWorkingHours(prev => ({ ...prev, from: val }))}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={30}
                          timeCaption="Время с"
                          dateFormat="HH:mm"
                          minTime={new Date().setHours(8, 0, 0)}
                          maxTime={workingHours?.to ?? new Date().setHours(21, 0, 0)}
                          isClearable
                          locale="ru"
                          className="form-control date-start"
                        />

                        <DatePicker
                          placeholderText="до"
                          selected={workingHours?.to}
                          onChange={(val) => setWorkingHours(prev => ({ ...prev, to: val }))}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={30}
                          timeCaption="Время до"
                          dateFormat="HH:mm"
                          minTime={workingHours?.from ?? new Date().setHours(8, 0, 0)}
                          maxTime={new Date().setHours(21, 0, 0)}
                          isClearable
                          locale="ru"
                          className="form-control date-end"
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="elem-field">
                      <Label className="text-sm-right">
                        Комментарий к отправке
                      </Label>
                      <Input
                        type="textarea"
                        className="addresses__textarea"
                        onChange={(e) => setComment(e.target.value)}
                        value={comment}
                      />
                    </Col>
                  </Row>
                  <div className="buttons">
                    <Button
                      color="primary"
                      onClick={id ? updateAddressHandler : addAddressHandler}
                      className="addresses__save"
                    >
                      Сохранить адрес
                    </Button>
                    <Button
                      color="secondary"
                      onClick={() => setShowAddAddress(false)}
                    >
                      Отменить
                    </Button>
                  </div>
                </>
              ) : (
                <Button onClick={() => setShowAddAddress(true)} color="primary">
                  Добавить адрес
                </Button>
              )}
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};
