import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { Cut } from "components/Cut";
import { MySpinner } from "components/MySpinner";
import { removeProducts } from "services/api";
import { AddProductModal } from "../AddProductModal";
import "./orderProductTable.scss";
import { removeEnteringBalanceProduct, updateEnteringBalanceProduct } from "services/apiSupply";
import { priceFormat } from "utils/format";
import { toast } from "react-toastify";
import { RemoveButton } from "../RemoveButton";

export const EnteringBalanceProductTable = ({
  id,
  products,
  setProducts,
  getAndSetEnteringBalance,
  carriedOut
}) => {
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const selectRow = {
    mode: "checkbox",
    bgColor: "#bfbfbf",
    onSelect: (row, isSelect, rowIndex, e) => {
      setSelected((prev) => {
        if (!isSelect) {
          prev.splice(
            prev.findIndex((val) => val === row.id),
            1
          );
          return [...prev];
        }
        return [...prev, row.id];
      });
    },
    onSelectAll: (isSelect, rows, e) => {
      if (!isSelect) {
        setSelected([]);
      } else {
        setSelected(products.map((val) => val.id));
      }
    },
  };

  const tableProductsColumns = [
    {
      dataField: "article",
      text: "Артикул",
      formatter: (cell, row) => row.nomenclature?.article,
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "brand",
      text: "Бренд",
      formatter: (cell, row) => row.nomenclature?.brand,
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "name",
      text: "Наименование",
      formatter: (cell, row) => (
        <Cut>{row.nomenclature?.name}</Cut>
      ),
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "count",
      text: "Кол-во",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      headerClasses: () => "right",
      footerClasses: () => "right",
      classes: () => "editable right",
      footer: (columnData) => {
        const total = columnData.reduce((acc, item) => acc + parseInt(item), 0);
        return total;
      },
      editable: false,
    },
    {
      dataField: "price",
      text: "Цена",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      headerClasses: () => "right",
      footerClasses: () => "right",
      classes: () => "editable right",
      formatter: (cell) => priceFormat(cell)
    },
    {
      dataField: "provider",
      text: "Поставщик",
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
      editable: false,
    },
    {
      dataField: "cell",
      text: "Ячейка",
      formatter: (cell, row) => (
        <Cut>{row.nomenclature?.cell}</Cut>
      ),
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      editable: false,
    },
    {
      dataField: "",
      text: "",
      headerStyle: (colum, colIndex) => {
        return { width: "25%" };
      },
      formatter: (cell, row) => (
        <RemoveButton removeHandler={() => removeHandler(row.id)} />
      ),
      editable: false,
      footer: () => null,
      hidden: carriedOut,
    },
  ];

  const removeHandler = (id) => {
    if (!window.confirm("Вы действительно хотите удалить товар?")) {
      return;
    }
    setLoading(true);
    removeEnteringBalanceProduct({ id })
      .then((res) => {
        setProducts(res.products);
        toast.success("Товар успешно удален");
      })
      .catch((e) => toast.error(e.response?.data?.message))
      .finally(() => setLoading(false));
  };

  const productHandler = (oldData, newData, row) => {
    if (String(oldData) === String(newData)) return;
    setLoading(true);
    updateEnteringBalanceProduct(row)
      .then((res) => {
        setProducts(res.products);
        // sendTo1SHandler();
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Card className="entering-balance-product mb-0">
        <CardBody className="pt-2">
          {loading && <MySpinner overlay />}

          <Row>
            <Col md="7" className="button-block">
              <Button
                color="primary"
                onClick={() => setOpenModal(true)}
                className="button-wide"
              >
                Добавить товар
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <BootstrapTable
        wrapperClasses="entering-balance-product"
        keyField="id"
        data={products}
        hover={true}
        columns={tableProductsColumns}
        bootstrap4
        bordered={false}
        selectRow={selectRow}
        cellEdit={cellEditFactory({
          mode: "click",
          blurToSave: true,
          afterSaveCell: (oldData, newData, row) =>
            productHandler(oldData, newData, row),
        })}
      />
      <AddProductModal
        id={id}
        openModal={openModal}
        setOpenModal={setOpenModal}
        setProducts={setProducts}
        setLoading={setLoading}
        loading={loading}
        getAndSetEnteringBalance={getAndSetEnteringBalance}
      />
    </>
  );
};
