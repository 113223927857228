import React, { useEffect, useState } from "react";
import { Container, Card, CardBody, Form, Row, Col, Label, Input, InputGroup, Button } from "reactstrap";
import { Link, useLocation } from "react-router-dom";

import { getOrderPicking } from "services/api";
import { MySpinner } from "components/MySpinner";
import upak from "assets/img/upak.svg";

import "./orderPicking.scss";
import useQuery from "utils/useQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";

export const OrdersPickingPage = () => {
  const query = useQuery(useLocation);
  const [orderPicking, setOrderPicking] = useState([]);
  const [loading, setLoading] = useState(false);
  const [number, setNumber] = useState(query.get("number") || "");
  const [isShowReset, setIsShowReset] = useState(false);

  const getAndSetOrderPicking = () => {
    getOrderPicking({
      number
    })
      .then((res) => {
        setOrderPicking(res);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    getAndSetOrderPicking();
  }, []);

  const isSearch = () => {
    if (
      number
    ) {
      return true;
    }
    return false;
  };

  const searchHandler = () => {
    if (!isSearch()) return;
    setLoading(true);
    getAndSetOrderPicking();
  };

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      searchHandler();
    }
  };

  useEffect(() => {
    setIsShowReset(isSearch());
    if (!isSearch()) {
      setLoading(true);
      getAndSetOrderPicking();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    number
  ]);

  const resetFilter = () => {
    setNumber("");
  };

  return (
    <Container fluid className="p-0 order-picking">
      {loading && <MySpinner overlay />}

      <Card className="mb-0 filter">
        <CardBody>
          <h1 className="h3 mb-3">Заявки на сборку</h1>

          <Form className="mb-3">
            <Row>
              <Col md={3}>
                <Label>Заказ №</Label>
                <Input
                  name="number"
                  placeholder="По номеру"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                />
              </Col>
              <Col md={2}>
                <InputGroup className="with-label">
                  {isShowReset && (
                    <Button color="danger" onClick={() => resetFilter()}>
                      <FontAwesomeIcon icon={faWindowClose} />
                    </Button>
                  )}
                  <Button
                    color="primary"
                    onClick={searchHandler}
                    className="button-wide"
                  >
                    Поиск
                  </Button>
                </InputGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>

      <Card>
        <CardBody className="order-picking__wrapper">
          {orderPicking.map((item) => (
            <Link
              className="order-picking__item"
              key={item.id}
              to={`/stock/orderPicking/${item.id}`}
            >
              <div className="order-picking__number">{item.number}</div>
              <div className="order-picking__customer">{item.customer}</div>
              <div className="order-picking__icon">
                <img src={upak} />
              </div>
            </Link>
          ))}
        </CardBody>
      </Card>
    </Container>
  );
};
