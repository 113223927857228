import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import InputMask from "react-input-mask";
import { useParams, useHistory, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Select from "react-select";
import { toast } from "react-toastify";
import { CheckCircle } from "react-feather";

import { MySpinner } from "components/MySpinner";
import { dateFormat, dateTimeFormat } from "utils/format";
import { HeaderPage } from "components/HeaderPage";
import {
  carryOutMoving,
  createMoving,
  getMovingOne,
  getWarehouses,
  updateMoving,
} from "services/apiWarehouse";
import { MovingProductTable } from "features/Moving/MovingProductTable";
import { MovingProductTableNew } from "features/Moving/MovingProductTableNew";
import { AddProductModal } from "features/Moving/AddProductModal";
import { getOrderByNumber, updateNomenclature } from "services/api";

import "./moving.scss";

export const MovingOnePage = () => {
  const history = useHistory();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [isNew, setIsNew] = useState(!id ? true : false);
  const [orderNumber, setOrderNumber] = useState("");
  const [number, setNumber] = useState("");
  const [date, setDate] = useState("");
  const [comment, setComment] = useState("");
  const [warehouseOut, setWarehouseOut] = useState(null);
  const [warehouseIn, setWarehouseIn] = useState(null);
  const [warehouses, setWarehouses] = useState([]);
  const [products, setProducts] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [admin, setAdmin] = useState(null);
  const [carriedOut, setCarriedOut] = useState(false);
  const [order, setOrder] = useState({});

  const getAndSetWarehouses = () => {
    getWarehouses()
      .then(setWarehouses)
      .catch((e) => console.error(e));
  };

  const getAndSetOrder = () => {
    if (!orderNumber) return;
    setLoading(true);
    getOrderByNumber(orderNumber)
      .then((res) => {
        setProducts(
          res.products.map((val) => {
            return { ...val, countBefore: val.count, countAfter: val.count };
          })
        );
      })
      .catch((e) => toast.error(e.response?.data?.message))
      .finally(() => setLoading(false));
  };

  const getAndSetMoving = () => {
    getMovingOne(id)
      .then((res) => {
        setComment(res.comment);
        setAdmin(res.admin);
        setWarehouseIn({
          label: res.warehouseIn?.name,
          value: res.warehouseIn?.id,
        });
        setWarehouseOut({
          label: res.warehouseOut?.name,
          value: res.warehouseOut?.id,
        });
        setNumber(res.number);
        setProducts(res.products);
        setDate(dateTimeFormat(res.date));
        setCarriedOut(res.carriedOut);
        setOrder(res.order);
      })
      .catch((e) => toast.error(e.response?.data?.message))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getAndSetWarehouses();
    if (!id) return;
    setLoading(true);
    getAndSetMoving();
  }, []);

  const createMovingHandler = () => {
    setLoading(true);
    createMoving({
      comment,
      products,
      warehouseOut: warehouseOut?.value,
      warehouseIn: warehouseIn?.value,
      date,
    })
      .then((res) => {
        history.push(res.id);
        toast.success("Перемещение успешно создано");
      })
      .catch((e) => toast.error(e.response?.data?.message))
      .finally(() => setLoading(false));
  };

  const updateMovingHandler = () => {
    setLoading(true);
    updateMoving({
      id,
      comment,
      products,
      warehouseOut: warehouseOut?.value,
      warehouseIn: warehouseIn?.value,
      date,
    })
      .then((res) => {
        history.push(res.id);
        toast.success("Перемещение успешно обновлено");
      })
      .catch((e) => toast.error(e.response?.data?.message))
      .finally(() => setLoading(false));
  };

  const carryOutHandler = () => {
    setLoading(true);
    carryOutMoving({
      id,
      carryOut: !carriedOut,
    })
      .then((res) => {
        setCarriedOut(res.carriedOut);
        if (res.carriedOut) {
          toast.success("Перемещение успешно проведено");
        } else {
          toast.success("Перемещение успешно распроведено");
        }
        getAndSetMoving();
      })
      .catch((e) => toast.error(e.response?.data?.message))
      .finally(() => setLoading(false));
  };

  const cellHandler = (nomenclature, cell) => {
    setLoading(true);
    updateNomenclature({
      id: nomenclature,
      cell: cell?.value || ""
    })
      .then((res) => {
        toast.success("Ячейка успешно сохранена");
        getAndSetMoving();
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  const renderTitle = () => {
    if (isNew) {
      return `Новое перемещение товаров`;
    }

    return `Перемещение товаров № ${number || ""}`;
  };

  return (
    <Container fluid className="p-0 moving-one">
      <Helmet>
        <title>{renderTitle()}</title>
      </Helmet>
      {loading && <MySpinner overlay />}

      <HeaderPage title={renderTitle()}>
        {!isNew && (
          <>
            <div className="moving-one__date-block">
              <Label>Дата</Label>
              <InputMask
                id="date"
                name="date"
                type="text"
                onChange={(e) => setDate(e.target.value)}
                value={date}
                mask={"99.99.9999 99:99:99"}
                className="form-control date"
              />
            </div>
            <div className="moving-one__carried-out-button">
              {carriedOut && <CheckCircle color="green" />}

              <Button
                color="primary"
                onClick={carryOutHandler}
                className="button-wide ml-1"
              >
                {carriedOut ? `Распровести` : `Провести`}
              </Button>
            </div>
          </>
        )}
      </HeaderPage>

      <Card className="moving-one__form mb-0">
        <CardBody className="pb-3">
          {isNew && (
            <Row>
              <Col md="6">
                <Label className="text-sm-right">Заказ</Label>
                <InputGroup>
                  <Input
                    name="orderNumber"
                    placeholder="Заказ"
                    value={orderNumber || ""}
                    onChange={(e) => setOrderNumber(e.target.value)}
                  />
                  <Button color="primary" onClick={getAndSetOrder}>
                    загрузить
                  </Button>
                </InputGroup>
              </Col>
            </Row>
          )}

          <Row>
            <Col md="6" className="elem-field">
              <Label className="text-sm-right">Отправитель</Label>
              <Select
                name="warehouseOut"
                placeholder="Отправитель"
                className="react-select-container"
                classNamePrefix="react-select"
                options={warehouses.map((item) => {
                  return {
                    label: item.name,
                    value: item.id,
                  };
                })}
                value={warehouseOut}
                onChange={setWarehouseOut}
              />
            </Col>
          </Row>

          <Row>
            <Col md="6" className="elem-field">
              <Label className="text-sm-right">Склад-получатель</Label>
              <Select
                name="warehouseIn"
                placeholder="Склад-получатель"
                className="react-select-container"
                classNamePrefix="react-select"
                options={warehouses.map((item) => {
                  return {
                    label: item.name,
                    value: item.id,
                  };
                })}
                value={warehouseIn}
                onChange={setWarehouseIn}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Label className="text-sm-right">Комментарий</Label>
              <Input
                type="textarea"
                name="comment"
                placeholder="Комментарий"
                value={comment || ""}
                onChange={(e) => setComment(e.target.value)}
              />
            </Col>
          </Row>
          {order?.id && (
            <Row>
              <Col md="3">На основании заказа</Col>
              <Col md="9">
                <Link to={`/sales/orders/${order.id}`} target="_blank">
                  №{order.number}
                </Link>
              </Col>
            </Row>
          )}
          <Row>
            <Col md="3">Автор</Col>
            <Col md="9">
              <b>{admin}</b>
            </Col>
          </Row>

          {!carriedOut && (
            <Row>
              <Col md="2">
                <Button
                  color="primary"
                  className="button-wide"
                  onClick={() => setOpenModal(true)}
                >
                  Добавить товар
                </Button>
              </Col>
            </Row>
          )}

          {!isNew && (
            <Row>
              <Col>
                <Button
                  color="primary"
                  onClick={updateMovingHandler}
                  className="button-wide mr-3"
                >
                  Сохранить
                </Button>
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>
      {isNew ? (
        <MovingProductTableNew products={products} />
      ) : (
        <MovingProductTable
          products={products}
          setProducts={setProducts}
          loading={loading}
          setLoading={setLoading}
          noEdit={carriedOut}
          cellHandler={cellHandler}
        />
      )}
      <Card>
        <CardBody>
          {isNew && (
            <Button
              color="primary"
              onClick={createMovingHandler}
              className="button-wide"
            >
              Создать
            </Button>
          )}
        </CardBody>
      </Card>

      <AddProductModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        setProducts={setProducts}
      />
    </Container>
  );
};
