import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import {
  Container,
  Label,
  ButtonGroup,
  Card,
  CardBody,
  Row,
  Col,
  Input,
  Button,
  Alert,
} from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import Select from "react-select";
import { toast } from "react-toastify";
import DatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import { CheckCircle } from "react-feather";
import classNames from "classnames";
import "react-datepicker/dist/react-datepicker.css";

import { providersSuggestion } from "services/api";
import {
  carryOutSupply,
  createSupply,
  exportFilesForTsd,
  getSupplyCart,
  getSupplyLayoutSheet,
  getSupplyStatuses,
  setSupplyStatus,
  updateSupply,
} from "services/apiSupply";
import { MySpinner } from "components/MySpinner";
import { dateFormat } from "utils/format";
import { HeaderPage } from "components/HeaderPage";
import { getSupplyOne } from "services/apiSupply";
import { SupplyProductTable } from "features/Supply/SupplyProductTable";
import { ChangeLogSupply } from "features/Supply/ChangeLogSupply";
import { getWarehouses } from "services/apiWarehouse";
import { validHandler } from "utils/validation";
import { AddedFunctions } from "features/Supply/AddedFunctions";
import { Checkbox } from "components/Checkbox";
import { PlanFactModal } from "features/Supply/PlanFactModal";
import { useUser } from "features/Users/UserContext";

import "./supplyOne.scss";
import { StatusOrderToProvider } from "components/StatusOrderToProvider";
import { NomenclatureModal } from "features/Dashboard/NomenclatureModal";

registerLocale("ru", ru);

export const DOC_FULL = [
  { value: true, label: "Полный комплект" },
  { value: false, label: "Неполный комплект" },
];

export const NDS = [
  { value: true, label: "НДС 20%" },
  { value: false, label: "БЕЗ НДС" },
];

export const VALID = ["provider", "factNum", "date"];

export const VALID_BAILOR = ["provider", "date"];

export const SupplyOnePage = () => {
  const history = useHistory();
  const { id } = useParams();
  const { admins } = useUser();
  const [isNew, setIsNew] = useState(false);
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [products, setProducts] = useState([]);
  const [date, setDate] = useState(new Date());
  const [createdAt, setCreatedAt] = useState("");
  const [number, setNumber] = useState("");
  const [factNum, setFactNum] = useState("");
  const [docFull, setDocFull] = useState(false);
  const [provider, setProvider] = useState({});
  const [nds, setNds] = useState(true);
  const [suggestions, setSuggestions] = useState([]);
  const [admin, setAdmin] = useState({});
  const [storeKeeper, setStoreKeeper] = useState(null);
  const [carriedOut, setCarriedOut] = useState(false);
  const [warehouses, setWarehouses] = useState([]);
  const [warehouse, setWarehouse] = useState(null);
  const [statuses, setStatuses] = useState([]);
  const [status, setStatus] = useState(null);
  const [customsDeclaration, setCustomsDeclaration] = useState(null);
  const [additionalExpances, setAdditionalExpances] = useState([]);
  const [errors, setErrors] = useState([]);
  const [needValid, setNeedValid] = useState(false);
  const [error, setError] = useState("");
  const [bailor, setBailor] = useState(false);
  const [regulated, setRegulated] = useState(true);
  const [accepted, setAccepted] = useState(false);
  const [planFact, setPlanFact] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [search, setSearch] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);

  const providerMap = (res) =>
    res.map((item) => {
      return { value: item.id, label: item.name };
    });

  useEffect(() => {
    if (search.length < 3) {
      setSuggestions([]);
      return;
    }
    setSearchLoading(true);
    providersSuggestion(search)
      .then((res) => setSuggestions(providerMap(res)))
      .finally(() => setSearchLoading(false));
  }, [search]);

  const getAndSetSupply = () => {
    if (!id) {
      setIsNew(true);

      return;
    }
    setLoading(true);
    getSupplyOne(id)
      .then((res) => {
        setDate(new Date(res.date));
        setCreatedAt(dateFormat(res.createdAt));
        setNumber(res.number);
        setFactNum(res.factNum);
        setDocFull(res.docFull);
        setProvider({
          label: res.provider?.name,
          value: res.provider?.id,
        });
        setNds(res.nds);
        setProducts(res.products || []);
        setComment(res.comment || "");
        setAdmin(res.admin);
        setCarriedOut(res.carriedOut);
        setWarehouse({ label: res.warehouse?.name, value: res.warehouse?.id });
        setCustomsDeclaration(res.customsDeclaration);
        setAdditionalExpances(res.additionalExpances);
        setBailor(res.bailor);
        setRegulated(res.regulated);
        setAccepted(res.accepted);
        setPlanFact(res.planFact);
        setStoreKeeper({
          label: res.storeKeeper?.name,
          value: res.storeKeeper?.id,
        });
        setStatus({
          ...res.status,
          label: res.status?.name,
          value: res.status?.id,
        });
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  const getAndSetCart = () => {
    if (id) return;
    getSupplyCart()
      .then((res) => setProducts(res || []))
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  const getAndSetStatuses = () => {
    getSupplyStatuses()
      .then((res) => {
        setStatuses(res);
      })
      .catch((e) => console.error(e));
  };

  const getAndSetWarehouses = () => {
    getWarehouses()
      .then(setWarehouses)
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    if (!warehouse && warehouses.length > 0) {
      setWarehouse({ label: warehouses[0]?.name, value: warehouses[0]?.id });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouses]);

  useEffect(() => {
    setLoading(true);
    getAndSetSupply();
    getAndSetWarehouses();
    getAndSetCart();
    getAndSetStatuses();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dateValidate = (val) => {
    const dateNow = new Date();
    if (val.getTime() > dateNow.getTime()) {
      toast.error("Дата не может быть больше сегодняшней");
      return;
    }
    setDate(val);
  };

  const supplyHandler = () => {
    if (!id) return;

    setLoading(true);
    updateSupply({
      id,
      date: dateFormat(date),
      provider: provider?.value,
      factNum,
      comment,
      nds,
      docFull,
      regulated,
      storeKeeper: storeKeeper?.value,
    })
      .then((res) => {
        setDate(new Date(res.date));
        setCreatedAt(dateFormat(res.createdAt));
        setNumber(res.number);
        setFactNum(res.factNum);
        setDocFull(res.docFull);
        setProvider({
          label: res.provider?.name,
          value: res.provider?.id,
        });
        setNds(res.nds);
        setProducts(res.products);
        setComment(res.comment || "");
        setWarehouse({ label: res.warehouse?.name, value: res.warehouse?.id });
        setRegulated(res.regulated);
        setAccepted(res.accepted);
        setPlanFact(res.planFact);
        setStoreKeeper({
          label: res.storeKeeper?.name,
          value: res.storeKeeper?.id,
        });
        toast.success("Изменения успешно сохранены");
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const { errors } = validHandler(
      {
        provider: provider.value,
        factNum,
        date,
      },
      bailor ? VALID_BAILOR : VALID
    );
    setErrors(errors);
    // eslint-disable-next-line
  }, [provider, factNum, date]);

  useEffect(() => {
    if (!isNew) return;
    if (bailor) {
      setWarehouse({ label: warehouses[0]?.name, value: warehouses[0]?.id });
    }
  }, [bailor]);

  useEffect(() => {
    if (loading) return;
    if (!storeKeeper?.value) {
      return;
    }
    supplyHandler();
  }, [storeKeeper?.value]);

  const createSupplyHandler = () => {
    setNeedValid(true);

    const { valid, errors } = validHandler(
      {
        provider: provider.value,
        factNum,
        date,
      },
      bailor ? VALID_BAILOR : VALID
    );

    setErrors(errors);

    if (!valid) return;

    setLoading(true);
    createSupply({
      date: dateFormat(date),
      provider: provider.value,
      factNum,
      comment,
      nds,
      docFull,
      warehouse: warehouse.value,
      products,
      bailor,
    })
      .then((res) => {
        history.push(res.id);
        toast.success("Поступление успешно создано");
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  const carryOutHandler = () => {
    if (!id) return;

    setLoading(true);
    carryOutSupply({
      id,
      carryOut: carriedOut ? 0 : 1,
    })
      .then((res) => {
        setCarriedOut(res.carriedOut);
        setProducts(res.products);
        if (res.carriedOut) {
          toast.success("Поступление успешно проведено");
        } else {
          toast.success("Поступление успешно распроведено");
        }
        setError("");
      })
      .catch((e) => {
        setError(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  const getLayoutSheetHandler = () => {
    if (!id) return;
    setLoading(true);
    getSupplyLayoutSheet({ id })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Раскладочный лист № ${number}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => {
        setError(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  const statusHandler = (status) => {
    setLoading(true);
    setSupplyStatus({
      id,
      statusId: status?.id,
    })
      .then(() => {
        getAndSetSupply();
      })
      .catch((e) => {
        toast.error(e.response?.data?.detail);
      })
      .finally(() => setLoading(false));
  };

  const renderTitle = () => {
    if (isNew) {
      return `Новое поступление`;
    }

    return `Поступление № ${number || ""} от ${provider?.label || ""}`;
  };

  const customStyles = {
    control: (base, state) => {
      return {
        ...base,
        borderColor: state.isFocused
          ? "#ced4da"
          : needValid && errors.hasOwnProperty(state.selectProps.name)
            ? "#FF0000!important"
            : "#ced4da",
        "&:hover": {
          borderColor: state.isFocused
            ? "#ced4da"
            : needValid && errors.hasOwnProperty(state.selectProps.name)
              ? "#FF0000!important"
              : "#ced4da",
        },
      };
    },
  };

  const exportToTsdHandler = () => {
    setLoading(true);
    exportFilesForTsd({ id })
      .then((res) => {
        toast.success("Поступление отправлено на ТСД");
      })
      .catch((e) => {
        setError(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  // const addCellHandler = () => {
  //   const regex = /^\d{1,2}-[A-Za-z]-\d{1,2}$/;
  //   if (!regex.test(newCell)) {
  //     toast.error("Ячейка должна быть вида 9-A-9 или 99-A-99");
  //     return;
  //   }
  //   setLoading(true);
  //   addCell({ name: newCell })
  //     .then((res) => {
  //       toast.success("Ячейка успешно добавлена");
  //     })
  //     .catch((e) => toast.error(e.response?.data?.message))
  //     .finally(() => {
  //       setLoading(false);
  //       setShowNewCell(false);
  //     });
  // };

  return (
    <Container fluid className="p-0 supply">
      <Helmet>
        <title>{renderTitle()}</title>
      </Helmet>
      <HeaderPage title={renderTitle()}>
        <>
          {!isNew && (
            <>
              <div className="supply__date-block">
                <Label>Дата</Label>
                <ButtonGroup>
                  <InputMask
                    id="createdAt"
                    name="createdAt"
                    type="text"
                    onChange={() => { }}
                    value={createdAt}
                    mask={"99.99.9999"}
                    className="form-control date"
                    disabled
                  />
                  <ChangeLogSupply id={id} />
                </ButtonGroup>
              </div>

              <div className="supply__carried-out-button">
                {carriedOut && <CheckCircle color="green" />}

                <Button
                  color="primary"
                  onClick={carryOutHandler}
                  className="button-wide ml-1"
                  disabled={loading}
                >
                  {carriedOut ? `Распровести` : `Провести`}
                </Button>
                {!loading && (
                  <div className="supply__accepted">
                    {accepted && <span className="green">Принят</span>}

                    {!accepted && planFact.length > 0 && (
                      <span
                        className="supply__not_accepted"
                        onClick={() => setOpenModal(true)}
                      >
                        Не принят
                      </span>
                    )}
                  </div>
                )}
              </div>
            </>
          )}
        </>
      </HeaderPage>

      {loading && <MySpinner overlay />}
      {error && <Alert color="danger">{error}</Alert>}

      <Card className="supply__form mb-0">
        <CardBody className="pb-3">
          <Row>
            <Col md="6" className="elem-field">
              <Label className="text-sm-right">Со склада СВХ</Label>
              <Checkbox
                value="bailor"
                checked={bailor}
                onChange={(e) => setBailor(e.target.checked)}
                disabled={isNew ? false : true}
              />
            </Col>
          </Row>

          <Row>
            <Col md="6" className="elem-field">
              <Label className="text-sm-right">Поставщик</Label>
              <Select
                name="provider"
                placeholder="Выберите поставщика"
                className="react-select-container"
                classNamePrefix="react-select"
                options={suggestions}
                isSearchable
                isLoading={searchLoading}
                inputValue={search}
                onInputChange={setSearch}
                value={provider}
                onChange={setProvider}
                styles={customStyles}
              />
            </Col>
          </Row>
          <Row>
            <Col md="3" className="elem-field">
              <Label className="text-sm-right">Номер счет-фактуры</Label>
              <Input
                name="factNum"
                placeholder="Номер счет-фактуры"
                value={factNum}
                onChange={(e) => setFactNum(e.target.value)}
                invalid={needValid && errors.hasOwnProperty("factNum")}
              />
            </Col>
            <Col md="3" className="elem-field">
              <Label className="text-sm-right">Дата накладной</Label>
              <DatePicker
                placeholderText="Дата накладной"
                selected={date}
                onChange={dateValidate}
                selectsStart
                dateFormat="dd.MM.yyyy"
                locale="ru"
                className={classNames("form-control", {
                  error: needValid && errors.hasOwnProperty("date"),
                })}
              />
            </Col>
          </Row>
          <Row>
            <Col md="3" className="elem-field">
              <Label className="text-sm-right">Ндс</Label>
              <Select
                name="nds"
                placeholder="Ндс"
                className="react-select-container"
                classNamePrefix="react-select"
                options={NDS}
                value={NDS.find((v) => nds === v.value)}
                onChange={(val) => setNds(val.value)}
              />
            </Col>
            <Col md="3" className="elem-field">
              <Label className="text-sm-right">Комплект документов</Label>
              <Select
                name="docFull"
                placeholder="Комплект документов"
                className="react-select-container"
                classNamePrefix="react-select"
                options={DOC_FULL}
                value={DOC_FULL.find((v) => docFull === v.value)}
                onChange={(val) => setDocFull(val.value)}
              />
            </Col>

            <Col md="3" className="elem-field">
              <Label>Регл. учет</Label>
              <Checkbox
                name="regulated"
                value="regulated"
                checked={regulated}
                onChange={(e) => {
                  if (e.target.checked === false) {
                    if (
                      window.confirm(
                        "Если убрать галочку, то поступление не выгрузится в 1С!"
                      )
                    ) {
                      setRegulated(false);
                      return;
                    }
                    setRegulated(true);
                    return;
                  }
                  setRegulated(true);
                }}
              />
            </Col>

          </Row>
          <Row>
            <Col>
              <Label className="text-sm-right">Комментарий</Label>
              <Input
                type="textarea"
                name="comment"
                placeholder="Комментарий"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </Col>
          </Row>
          {!isNew && (
            <>
              <Row>
                <Col md="3">
                  <Label className="text-sm-right">Автор</Label>
                  <Input value={admin.name} disabled />
                </Col>
                <Col md="3">
                  <Label className="text-sm-right">Приемщик</Label>
                  <Select
                    name="storeKeeper"
                    placeholder="Приемщик"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={admins.filter(
                      (item) => item.department === "warehouse"
                    )}
                    value={storeKeeper}
                    onChange={setStoreKeeper}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <StatusOrderToProvider
                    status={status}
                    statuses={statuses}
                    statusHandler={statusHandler}
                  >
                    {status?.name}
                  </StatusOrderToProvider>
                </Col>
              </Row>
            </>
          )}

          <AddedFunctions
            id={id}
            customsDeclaration={customsDeclaration}
            additionalExpances={additionalExpances}
            getAndSetSupply={getAndSetSupply}
            setLoading={setLoading}
            isNew={isNew}
          />
        </CardBody>
      </Card>

      {!isNew && (
        <Card className="supply__button-save mb-0">
          <CardBody className="pt-1">
            <Button
              color="primary"
              onClick={supplyHandler}
              className="button-wide"
            >
              Сохранить
            </Button>

            <Button className="ml-3" onClick={getLayoutSheetHandler}>
              Скачать раскладку
            </Button>

            <Button className="ml-3" onClick={exportToTsdHandler}>
              Выгрузить на ТСД
            </Button>
          </CardBody>
        </Card>
      )}

      <SupplyProductTable
        id={id}
        status={status}
        products={products}
        setProducts={setProducts}
        carriedOut={carriedOut}
        setCarriedOut={setCarriedOut}
        bailor={bailor}
        isNew={isNew}
        provider={provider}
        warehouses={warehouses}
        getAndSetSupply={getAndSetSupply}
      />

      <PlanFactModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        planFact={planFact}
      />

      {products?.length > 0 && (
        <Card>
          <CardBody>
            {isNew && (
              <Button
                color="primary"
                onClick={createSupplyHandler}
                className="button-wide"
              >
                Создать поступление
              </Button>
            )}
          </CardBody>
        </Card>
      )}

    </Container>

  );
};
