import { MySpinner } from "components/MySpinner";
import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";

import useQuery from "utils/useQuery";
import { Pagination } from "components/Pagination";
import {
  addDeduction,
  generateSalaryReport,
  getProgressAnaliticsReceivables,
  getProgressSalaryReport,
  getSalaryReport,
} from "services/apiAnalitics";
import { SalaryReportTable } from "features/SalaryReport/SalaryReportTable";
import { dateFormatU, priceFormat } from "utils/format";
import { SelectCustomer } from "features/Dashboard/SelectCustomer";
import { brandsSuggestion, getAdmins, getAdminsForSelect } from "services/api";
import { useUser } from "features/Users/UserContext";
import { toast } from "react-toastify";

import "./salaryReport.scss";
import { SelectProvider } from "components/SelectProvider";
import { SelectSearch } from "components/SelectSearch";

export const SalaryReportPage = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { user } = useUser();
  const query = useQuery(useLocation);
  const { admins } = useUser();
  const [salaryReport, setSalaryReport] = useState([]);
  const [salaryReportCount, setSalaryReportCount] = useState(0);
  const [salaryReportTotalSumm, setSalaryReportTotalSumm] = useState(0);
  const [salaryReportTotalCostPrice, setSalaryReportTotalCostPrice] =
    useState(0);
  const [salaryReportTotalAddExpenses, setSalaryReportTotalAddExpenses] =
    useState(0);
  const [loadingGenerate, setLoadingGenerate] = useState(false);
  const [progress, setProgress] = useState({});
  const [salaryReportTotalCorr, setSalaryReportTotalCorr] = useState(0);
  const [salaryReportTotalMargin, setSalaryReportTotalMargin] = useState(0);
  const [salaryReportTotalDeduction, setSalaryReportTotalDeduction] = useState(0);
  const [salaryReportTotalDeliverySum, setSalaryReportTotalDeliverySum] = useState(0);
  const [salaryReportTotalSalary, setSalaryReportTotalSalary] = useState(0);
  const [salaryTable, setSalaryTable] = useState([]);
  const [intervalProgress, setIntervalProgress] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isShowReset, setIsShowReset] = useState(false);
  const [deduction, setDeduction] = useState(0);

  const [dateStart, setDateStart] = useState(
    query.get("dateStart") ? new Date(query.get("dateStart")) : ""
  );
  const [dateEnd, setDateEnd] = useState(
    query.get("dateEnd") ? new Date(query.get("dateEnd")) : ""
  );

  const [dateStartGenerate, setDateStartGenerate] = useState("");
  const [dateEndGenerate, setDateEndGenerate] = useState("");
  const [customer, setCustomer] = useState(
    query.get("customer")
      ? { label: query.get("customerName"), value: query.get("customer") }
      : null
  );

  const [brand, setBrand] = useState(
    query.get("brand")
      ? { label: query.get("brandName"), value: query.get("brand") }
      : null
  );
  const [admin, setAdmin] = useState(
    query.get("admin")
      ? { label: query.get("adminName"), value: query.get("admin") }
      : null
  );
  const [currentPage, setCurrentPage] = useState(
    query.get("page") ? Number(query.get("page")) : 1
  );

  const [provider, setProvider] = useState(
    query.get("provider")
      ? { label: query.get("providerName"), value: query.get("provider") }
      : null
  );

  const getPageSize = localStorage.getItem("pageSize") ? JSON.parse(localStorage.getItem("pageSize")) : {};
  const [pageSize, setPageSize] = useState(getPageSize[pathname] || 10);

  const getAndSetSalaryReport = () => {
    getSalaryReport({
      paginationCount: pageSize,
      paginationOffset: (currentPage - 1) * pageSize,
      admin: admin?.value,
      dateStart: dateFormatU(dateStart),
      dateEnd: dateFormatU(dateEnd),
      brand: brand?.value,
      customer: customer?.value,
      provider: provider?.value,
    })
      .then((res) => {
        setSalaryReport(res.items);
        setSalaryReportCount(res.totalItems);
        setSalaryReportTotalSumm(res.totalSumm);
        setSalaryReportTotalCostPrice(res.totalCostPrice);
        setSalaryReportTotalAddExpenses(res.totalAddExpenses);
        setSalaryReportTotalCorr(res.totalCorr);
        setSalaryReportTotalMargin(res.totalMargin);
        setSalaryReportTotalDeduction(res.totalDeduction);
        setSalaryReportTotalSalary(res.totalSalary);
        setSalaryReportTotalDeliverySum(res.totalDeliverySum);
        setSalaryTable(res.salaryTable);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!loadingGenerate) {
      setLoading(true);
      getAndSetSalaryReport();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  const getAndSetProgress = () => {
    getProgressSalaryReport().then((res) => {
      setProgress(res);

      if (res?.progress) {
        setLoadingGenerate(true);
      } else {
        if (loadingGenerate) {
          toast.success("Генерация отчета завершена");
          setLoadingGenerate(false);
        }
      }
    });
  };

  const generateSalaryRepostHandler = () => {
    setLoadingGenerate(true);
    toast.success("Генерация отчета запущена");
    generateSalaryReport({
      dateStart: dateFormatU(dateStartGenerate),
      dateEnd: dateFormatU(dateEndGenerate),
    })
      .then((res) => { })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      });
  };

  const setDeductionHandler = () => {
    if (!deduction) {
      toast.error("Укажите значение");
      return;
    }
    setLoading(true);
    addDeduction({
      summ: deduction,
    })
      .then((res) => toast.success("Вычет успешно сохранен"))
      .catch((e) => toast.error(e.response?.data?.message))
      .finally(() => {
        setDeduction(0);
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    getAndSetProgress();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loadingGenerate) {
      setIntervalProgress(
        setInterval(() => {
          getAndSetProgress();
        }, 1000)
      );
    } else {
      clearInterval(intervalProgress);
      setIntervalProgress(null);
      setLoadingGenerate(false);
    }

    return () => {
      clearInterval(intervalProgress);
    };
  }, [loadingGenerate]);

  useEffect(() => {
    const url = new URLSearchParams();
    if (currentPage !== 1) {
      url.append("page", currentPage);
    }

    if (admin?.value) {
      url.append("admin", admin?.value);
      url.append("adminName", admin?.label);
    }

    if (customer?.value) {
      url.append("customer", customer?.value);
      url.append("customerName", customer?.label);
    }

    if (dateStart) {
      url.append("dateStart", dateFormatU(dateStart));
    }

    if (dateEnd) {
      url.append("dateEnd", dateFormatU(dateEnd));
    }

    if (brand?.value) {
      url.append("brand", brand?.value);
      url.append("brandName", brand?.label);
    }

    if (provider?.value) {
      url.append("provider", provider?.value);
      url.append("providerName", provider?.label);
    }

    if (url) {
      history.push(`${pathname}?${url}`);
    } else {
      history.push(pathname);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    pageSize,
    customer?.value,
    admin?.value,
    dateStart,
    dateEnd,
    brand?.value,
    provider?.value,
  ]);

  const isSearch = () => {
    if (
      customer?.value ||
      admin?.value ||
      dateStart ||
      dateEnd ||
      brand?.value ||
      provider?.value
    ) {
      return true;
    }
    return false;
  };

  const searchHandler = () => {
    if (!isSearch()) return;
    if (currentPage !== 1) {
      setCurrentPage(1);
      return;
    }
    setLoading(true);
    getAndSetSalaryReport();
  };

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      searchHandler();
    }
  };

  useEffect(() => {
    setIsShowReset(isSearch());
    if (!isSearch()) {
      setLoading(true);
      getAndSetSalaryReport();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customer?.value,
    admin?.value,
    dateStart,
    dateEnd,
    brand?.value,
    provider?.value,
  ]);

  const resetFilter = () => {
    setAdmin(null);
    setCustomer(null);
    setDateStart("");
    setDateEnd("");
    setBrand(null);
    setProvider(null);
  };

  const FilterAdmin = () => {
    if (!user.roles?.find((role) => role === "ROLE_ADMIN")) {
      return null;
    }

    return (
      <Col md={3}>
        <Label>Менеджер</Label>
        <Select
          name="admin"
          placeholder="Ответственный менеджер"
          className="react-select-container"
          classNamePrefix="react-select"
          options={admins}
          value={admin}
          onChange={(val) => setAdmin(val)}
          isClearable
        />
      </Col>
    );
  };

  return (
    <Container fluid className="p-0 salary-report">
      {loading && <MySpinner overlay />}
      <Card className="mb-0 filter">
        <CardBody>
          <h1 className="h3 mb-3">Отчет по зарплате</h1>
          <Form className="mb-3">
            <Row>
              <Col md={3}>
                <Label>Дата</Label>

                <InputGroup className="daterange">
                  <DatePicker
                    placeholderText="от"
                    selected={dateStart}
                    onChange={setDateStart}
                    selectsStart
                    startDate={dateStart}
                    endDate={dateEnd}
                    dateFormat="dd.MM.yyyy"
                    locale="ru"
                    className="form-control date-start"
                  />
                  <DatePicker
                    placeholderText="до"
                    selected={dateEnd}
                    onChange={setDateEnd}
                    selectsEnd
                    startDate={dateStart}
                    endDate={dateEnd}
                    minDate={dateStart}
                    dateFormat="dd.MM.yyyy"
                    locale="ru"
                    className="form-control date-end"
                  />
                </InputGroup>
              </Col>
              <Col md={3}>
                <Label>Клиент</Label>
                <SelectCustomer customer={customer} setCustomer={setCustomer} />
              </Col>
              <Col md={3}>
                <Label>Поставщик</Label>
                <SelectProvider provider={provider} setProvider={setProvider} />
              </Col>
              <Col md={3}>
                <Label>Бренд</Label>
                <SelectSearch
                  name="brand"
                  placeholder="Выберите бренд"
                  functionSuggestion={brandsSuggestion}
                  value={brand}
                  setValue={setBrand}
                />
              </Col>
              <FilterAdmin />
              <Col md={3}>
                <InputGroup className="with-label">
                  {isShowReset && (
                    <Button color="danger" onClick={() => resetFilter()}>
                      <FontAwesomeIcon icon={faWindowClose} />
                    </Button>
                  )}
                  <Button
                    color="primary"
                    onClick={searchHandler}
                    className="button-wide"
                  >
                    Поиск
                  </Button>
                </InputGroup>
              </Col>
            </Row>
          </Form>

          <table class="table table-hover">
            <thead>
              <th>Менеджер</th>
              <th className="right">Объем продаж</th>
              <th className="right">Себестоимость</th>
              <th className="right">Доп. расходы</th>
              <th className="right">Возвраты</th>
              <th className="right">Маржа</th>
              <th className="right">Вычет</th>
              <th className="right">Доставка</th>
              <th className="right">Зарплата</th>
            </thead>
            <tbody>
              {salaryTable.map((item) => (
                <tr key={item.id}>
                  <td>{item.admin}</td>
                  <td className="right">{priceFormat(item.summ)}</td>
                  <td className="right">{priceFormat(item.costPrice)}</td>
                  <td className="right">{priceFormat(item.addExpenses)}</td>
                  <td className="right">{priceFormat(item.corr)}</td>
                  <td className="right">{priceFormat(item.margin)}</td>
                  <td className="right">{priceFormat(item.deduction)}</td>
                  <td className="right">{priceFormat(item.deliverySum)}</td>
                  <td className="right">{priceFormat(item.salary)}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td></td>
                <td className="right bold">
                  {priceFormat(salaryReportTotalSumm)}
                </td>
                <td className="right bold">
                  {priceFormat(salaryReportTotalCostPrice)}
                </td>
                <td className="right bold">
                  {priceFormat(salaryReportTotalAddExpenses)}
                </td>
                <td className="right bold">
                  {priceFormat(salaryReportTotalCorr)}
                </td>
                <td className="right bold">
                  {priceFormat(salaryReportTotalMargin)}
                </td>
                <td className="right bold">
                  {priceFormat(salaryReportTotalDeduction)}
                </td>
                <td className="right bold">
                  {priceFormat(salaryReportTotalDeliverySum)}
                </td>
                <td className="right bold">
                  {priceFormat(salaryReportTotalSalary)}
                </td>
              </tr>
            </tfoot>
          </table>
          <Row>
            <Col md={3}>
              <InputGroup className="with-label">
                <Input
                  value={deduction}
                  onChange={(e) => setDeduction(e.target.value)}
                />

                <Button
                  color="primary"
                  onClick={setDeductionHandler}
                  className="button-wide"
                >
                  Сохранить
                </Button>
              </InputGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <div>
        <SalaryReportTable salaryReport={salaryReport} />

        <Card>
          <CardBody>
            <Pagination
              totalCount={salaryReportCount}
              currentPage={currentPage}
              pageSize={pageSize}
              setCurrentPage={setCurrentPage}
              onChangePageSize={setPageSize}
            />
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};
