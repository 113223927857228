import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Container, Row, Label, Col, CardBody, Card } from "reactstrap";
import { toast } from "react-toastify";
import { CheckCircle } from "react-feather";
import Select from "react-select";
import { createPortal } from "react-dom";
import { Helmet } from "react-helmet";

import { HeaderPage } from "components/HeaderPage";
import {
  baseUrl,
  endPicking,
  getOrder,
  getUpd,
  updateOrder,
} from "services/api";

import { MySpinner } from "components/MySpinner";
import { useUser } from "features/Users/UserContext";
import { OrderPickingProductTable } from "features/OrderPicking/OrderPickingProductTable";
import { dateTimeFormat } from "utils/format";
import { LabelModal } from "features/Orders/LabelModal";
import { PrintLabel } from "features/Orders/PrintLabel";

import "./orderPickingOne.scss";

export const OrdersPickingOnePage = () => {
  const { id } = useParams();
  const [order, setOrder] = useState({});
  const [loading, setLoading] = useState(false);
  const [openModalLabel, setOpenModalLabel] = useState(false);
  const { user, admins } = useUser();
  const [storeKeeper, setStoreKeeper] = useState(null);
  const [quantityLabels, setQuantityLabels] = useState(0);
  const [quantityPallets, setQuantityPallets] = useState(0);
  const [showPrintLabels, setShowPrintLabels] = useState(false);
  const [utd, setUtd] = useState(null);

  const getAndSetOrder = () => {
    getOrder(id)
      .then((res) => {
        setOrder(res);
        setQuantityLabels(res?.settings?.quantityLabels);
        setQuantityPallets(res?.settings?.quantityPallets);
        if (res.storeKeeper) {
          setStoreKeeper({
            value: res.storeKeeper?.id,
            label: res.storeKeeper?.name,
          });
        }
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));


    getUpd({ id, json: true })
      .then(setUtd)
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    getAndSetOrder();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const endPickingHandler = () => {
    if (!window.confirm("Вы действительно хотите завершить сборку?")) {
      return;
    }

    if (!storeKeeper?.value) {
      toast.error("Необходимо выбрать сборщика");
      return;
    }

    endPicking({ id })
      .then((res) => {
        setOrder(res);
        toast.success("Заказ успешно собран");
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  const orderHandler = (storeKeeper) => {
    setStoreKeeper(storeKeeper);
    if (!order.id) return;
    setLoading(true);
    updateOrder({
      id: order.id,
      storeKeeper: storeKeeper?.value,
    })
      .then((res) => {
        setOrder(res);
        toast.success("Заявка успешно сохранена");
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  if (!loading && order.status?.sort < 4) {
    return <>Заявки на сборку не существует</>;
  }

  return (
    <>
      {createPortal(
        <>
          {showPrintLabels && utd &&
            <PrintLabel order={order} utd={utd} quantityLabels={quantityLabels} />
          }
        </>,
        document.body
      )}
      <Container fluid className="p-0 order-picking-one">
        <Helmet>
          <title>{`Заявка на сборку заказа № ${order.number || ""}`}</title>
        </Helmet>
        <HeaderPage title={`Заявка на сборку заказа № ${order.number || ""}`} />

        {loading && <MySpinner overlay />}
        <Card className="order-picking-one__form mb-0">
          <CardBody className="pb-3">
            <Row>
              <Col md="4">
                <Label className="text-sm-right">Заказ</Label>
              </Col>
              <Col md="8">
                <b>
                  {order.number} от {dateTimeFormat(order.date)}
                </b>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Label className="text-sm-right">Клиент</Label>
              </Col>
              <Col md="8">
                <b>{order.customer?.name}</b>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Label className="text-sm-right">Город доставки</Label>
              </Col>
              <Col md="8">
                <b>{order.address?.city}</b>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Label className="text-sm-right">Адрес доставки</Label>
              </Col>
              <Col md="8">
                <b>{order.address?.address}</b>
              </Col>
            </Row>

            {order.deliveryType === 3 &&
              <>
                <Row>
                  <Col md="4">
                    <Label className="text-sm-right">Транспортная компания</Label>
                  </Col>
                  <Col md="8">
                    <b>{order.address?.transportCompany}</b>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <Label className="text-sm-right">Плательщик</Label>
                  </Col>
                  <Col md="8">
                    <b>
                      {order.deliverySettings?.senderIsPayes
                        ? "Отправитель"
                        : "Получатель"}
                    </b>
                  </Col>
                </Row>
              </>
            }
            <Row>
              <Col md="4">
                <Label className="text-sm-right">Контакты получателя</Label>
              </Col>
              <Col md="8">
                <b>{order.deliverySettings?.contact}</b>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Label className="text-sm-right">Комментарий к отгрузке</Label>
              </Col>
              <Col md="8">
                <b>{order.deliverySettings?.comment}</b>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Label className="text-sm-right">Менеджер</Label>
              </Col>
              <Col md="8">
                <b>{order.admin?.name}</b>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Label className="text-sm-right">Комментарий к заказу</Label>
              </Col>
              <Col md="8">
                <b>{order.comment}</b>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Label className="text-sm-right">Сборщик</Label>
              </Col>
              <Col md="8">
                <Select
                  name="storeKeeper"
                  placeholder="Сборщик"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={admins.filter((item) =>
                    item.department === "warehouse"
                  )}
                  value={storeKeeper}
                  onChange={(val) => orderHandler(val)}
                />
              </Col>
            </Row>
            <div className="button-block">
              {order.status?.sort === 4 && (
                <Button
                  onClick={endPickingHandler}
                  color="primary button-wide"
                >
                  Завершить сборку
                </Button>
              )}
              {order.status?.sort >= 5 && (
                <div className="end-picking">
                  <CheckCircle color="green" />
                  Сборка завершена
                </div>
              )}

              {utd && order && order.status?.sort >= 5 &&
                <Button
                  onClick={() => {
                    setOpenModalLabel(true);
                  }}
                  color="primary button-wide ml-3"
                >
                  Печать этикеток
                </Button>
              }

              <LabelModal
                id={id}
                openModal={openModalLabel}
                setOpenModal={setOpenModalLabel}
                setQuantityLabels={setQuantityLabels}
                quantityLabels={quantityLabels}
                setQuantityPallets={setQuantityPallets}
                quantityPallets={quantityPallets}
                setShowPrintLabels={setShowPrintLabels}
                showPrintLabels={showPrintLabels}
              />
            </div>
          </CardBody>
        </Card>

        <OrderPickingProductTable products={order.products} />
      </Container>
    </>
  );
};
