import React, { useState } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from "reactstrap";
import { useOrders } from "features/Orders/OrdersContext";
import { useUser } from "features/Users/UserContext";

import "./status.scss";

const STATUSES_ENABLED = {
  "344abcb7-2053-4f4d-88dd-56d6f7727187": [
    "5034bfba-bcc3-4715-ba0c-50b94af7f404",
    "7c423e57-2595-4bf8-add7-79f1560e3012",
    "b1847ff6-a5fd-4da3-83ad-59208309d32d",
    "440bd2ae-7fee-497a-9305-ba9fda1a1c8c",
  ],
  "b1847ff6-a5fd-4da3-83ad-59208309d32d": [
    "5034bfba-bcc3-4715-ba0c-50b94af7f404",
    "440bd2ae-7fee-497a-9305-ba9fda1a1c8c",
  ],
  "5034bfba-bcc3-4715-ba0c-50b94af7f404": [
    "7c423e57-2595-4bf8-add7-79f1560e3012",
    "440bd2ae-7fee-497a-9305-ba9fda1a1c8c",
  ],
  "d9fb279a-7531-4d49-914a-8c66ad60241d": [
    "7c423e57-2595-4bf8-add7-79f1560e3012",
    "440bd2ae-7fee-497a-9305-ba9fda1a1c8c"
  ],
  "7c423e57-2595-4bf8-add7-79f1560e3012": [],
  "f0cc8863-ca83-4b12-b7c6-0034f8555796": [],
  "14e019cf-6c2f-4984-a95e-c9e2d5521fe7": [],
  "440bd2ae-7fee-497a-9305-ba9fda1a1c8c": [],
};

export const Status = ({ children, order, statusHandler }) => {
  const { statuses } = useOrders();
  const [hover, setHover] = useState(false);

  const { user } = useUser();

  const renderDropdown = () => {
    if (user.roles?.find((role) => role === "ROLE_ADMIN")) {
      return statuses.map((status) => (
        <DropdownItem
          key={status.id}
          style={{ backgroundColor: status?.color }}
          className="status__item"
          onClick={() => statusHandler(status, order.order)}
        >
          {status?.name}
        </DropdownItem>
      ));
    }

    if (!STATUSES_ENABLED[order.status?.id]?.length) return null;

    return STATUSES_ENABLED[order.status?.id].map((id) => {
      const status = statuses.find((status) => status.id === id);
      if (!status) {
        return null;
      }

      return (
        <DropdownItem
          key={status?.id}
          style={{ backgroundColor: status?.color }}
          className="status__item"
          onClick={() => statusHandler(status, order.order)}
        >
          {status?.name}
        </DropdownItem>
      );
    });
  };

  const styles = () => {
    if (hover) {
      return {
        backgroundColor: order.status?.color,
        color: "white",
        borderColor: order.status?.color,
      };
    }

    return {
      color: order.status?.color,
      borderColor: order.status?.color,
    };
  };

  if (!children) return null;

  return (
    <UncontrolledButtonDropdown className="status">
      <DropdownToggle
        size="sm"
        style={styles()}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {children}
      </DropdownToggle>
      {renderDropdown() && <DropdownMenu>{renderDropdown()}</DropdownMenu>}
    </UncontrolledButtonDropdown>
  );
};
