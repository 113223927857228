import React, { useEffect, useState } from "react";

import "./printReturnToProvider.scss";
import { priceFormat } from "utils/format";

export const PrintReturnToProvider = ({
  number,
  date,
  supply,
  products,
  setShowPrint
}) => {

  useEffect(() => {

    setTimeout(() => {
      window.print();
    }, 200);

    setTimeout(() => {
      setShowPrint(false);
    }, 200);

  }, []);
  return (
    <>
      <style>
        {`@media print {
            @page {
              size: A4 portrait;
              margin: 0;
				    }}
          }`
        }
      </style>
      <div className="printReturnToProvider">
        <table className="top">
          <tbody>
            <tr>
              <td>
                Возврат поставщику №{number}
              </td>
              <td className="date" rowSpan={2}>
                {date}
              </td>
            </tr>
            <tr>
              <td>
                Поставщик: {supply.provider?.name}
              </td>
            </tr>
          </tbody>
        </table>

        <div className="title">АКТ ВОЗВРАТА ТОВАРА</div>

        <table className="products">
          <tbody>
            <tr>
              <th>
                №
              </th>
              <th>
                Артикул
              </th>
              <th>
                Наименование
              </th>
              <th className="center">
                Кол-во
              </th>
              <th className="center">
                Сумма (общая)
              </th>
            </tr>

            {products.map(item =>
              <tr key={item.id}>
                <td>
                  {item.index}
                </td>
                <td>
                  {item.nomenclature?.article}
                </td>
                <td>
                  {item.nomenclature?.name}
                </td>
                <td className="center">
                  {item.count}
                </td>
                <td className="center">
                  {priceFormat(item.summ)}
                </td>
              </tr>
            )}

          </tbody>
        </table>

        <table>
          <tbody>
            <tr>
              <td>
                Сдал (ФИО): ____________________________
              </td>
              <td>
                Получил (ФИО): _________________________
              </td>
            </tr>
            <tr>
              <td>
                Должность: _____________________________
              </td>
              <td>
                Должность: _____________________________
              </td>
            </tr>
            <tr>
              <td>
                М.П.
              </td>
              <td>
                М.П.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};