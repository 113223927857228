import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import { Cut } from "components/Cut";
import { MySpinner } from "components/MySpinner";
import {
  removeMovingProduct,
  updateMovingProduct,
} from "services/apiWarehouse";
import { RemoveButton } from "../RemoveButton";
import { BarChart } from "components/BarChart";

import "./movingProductTable.scss";
import { existRender } from "utils/existRender";
import { SelectCell } from "features/Supply/SelectCell";

export const MovingProductTable = ({
  products,
  setProducts,
  loading,
  setLoading,
  noEdit,
  cellHandler
}) => {
  const partlyCarriedOut = () => {
    const carriedOut = products.filter((val) => val.carriedOut === true);
    if (carriedOut.length > 0) {
      return true;
    }
    return false;
  };

  const productHandler = (oldData, newData, row) => {
    if (String(oldData) === String(newData)) return;
    setLoading(true);
    updateMovingProduct(row)
      .then((res) => {
        setProducts(res.products);
      })
      .finally(() => setLoading(false));
  };

  const removeHandler = (id) => {
    if (!window.confirm("Вы действительно хотите удалить товар?")) {
      return;
    }
    setLoading(true);
    removeMovingProduct({ id })
      .then((res) => setProducts(res.products))
      .finally(() => setLoading(false));
  };

  const tableProductsColumns = [
    {
      dataField: "index",
      text: "№",
      headerStyle: (colum, colIndex) => {
        return { width: "3%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "article",
      text: "Артикул",
      formatter: (cell, row) => row.nomenclature?.article,
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
      classes: () => "bold",
      editable: false,
      footer: () => null,
    },
    {
      dataField: "brand",
      text: "Бренд",
      formatter: (cell, row) => row.nomenclature?.brand,
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
      classes: () => "bold",
      editable: false,
      footer: () => null,
    },
    {
      dataField: "name",
      text: "Наименование",
      formatter: (cell, row) => (
        <Cut>{row.nomenclature?.name}</Cut>
      ),
      headerStyle: (colum, colIndex) => {
        return { width: "20%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "inStock",
      text: "Ост.",
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
      formatter: (cell, row) => (
        <div className="warehouse-container">
          {(cell || row.inStockAll) && (
            <BarChart
              color={cell > 0 ? "green" : "red"}
              count={existRender(row)}
            />
          )}
        </div>
      ),
      headerClasses: () => "right",
      classes: (cell) => "right",
      footer: () => null,
      editable: false,
    },
    {
      dataField: "count",
      text: "Перем.",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      headerClasses: () => "right",
      footerClasses: () => "right",
      classes: () => "right",
      footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
    },
    {
      dataField: "cell",
      text: "Ячейка",
      formatter: (cell, row) =>
        <SelectCell row={row} cellHandler={(val) => cellHandler(row.nomenclature?.id, val)} />,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
      editable: false,
      events: {
        onClick: (e) => {
          e.stopPropagation();
        },
      },
    },
    {
      dataField: "",
      text: "",
      headerStyle: (colum, colIndex) => {
        return { width: "50%" };
      },
      formatter: (cell, row) => <RemoveButton removeHandler={() => removeHandler(row.id)} />,
      editable: false,
      footer: () => null,
      hidden: noEdit,
    },
  ];

  const rowClasses = (row, rowIndex) => {
    if (!row.carriedOut && partlyCarriedOut) {
      return "error";
    }
  };

  return (
    <>
      {loading && <MySpinner overlay />}
      <BootstrapTable
        wrapperClasses="moving-product"
        keyField="id"
        data={products}
        columns={tableProductsColumns}
        bootstrap4
        bordered={false}
        rowClasses={rowClasses}
        cellEdit={cellEditFactory({
          mode: "click",
          blurToSave: true,
          afterSaveCell: (oldData, newData, row) =>
            productHandler(oldData, newData, row),
        })}
      />
    </>
  );
};
