import { CloseButton } from "components/CloseButton";
import { MySpinner } from "components/MySpinner";
import { useCart } from "features/Cart/CartContext";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import Moment from 'react-moment';
import moment from 'moment';
import { toast } from "react-toastify";
import DatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Label,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  InputGroup,
  Input,
  Alert,
} from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";

import { createOrder, getAccounts, getAddresses, getContracts } from "services/api";
import { CreateAddressModal } from "components/CreateAddressModal";
import { cut } from "utils/cut";

import { SelectCustomer } from "../SelectCustomer";

import "./orderForm.scss";
import ReactInputMask from "react-input-mask";
import { dateFormat } from "utils/format";


registerLocale("ru", ru);

export const DELIVERY = [
  { value: 1, label: "Самовывоз" },
  { value: 2, label: "Доставка курьером" },
  { value: 3, label: "Доставка до ТК" },
];

export const addressLabel = (item) => {
  const label = [];
  if (item.transportCompany) {
    label.push(item.transportCompany);
  }
  if (item.city) {
    label.push(item.city);
  }
  if (item.address) {
    label.push(cut(item.address));
  }
  return label.join(", ");
}

export const addressesMap = (res) =>
  res.map((item) => ({
    id: item.id,
    label: addressLabel(item),
    value: item.id,
    main: item.main,
    city: item.city,
    address: item.address,
    contact: item.contact,
    comment: item.comment,
    transportCompany: item.transportCompany,
    senderIsPayer: item.senderIsPayer,
  }));

export const OrderForm = ({
  customer,
  setCustomer,
  account,
  setAccount,
  getAndSetPrice,
  setOrderSuccess,
}) => {
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [deliveryType, setDeliveryType] = useState({});
  const [comment, setComment] = useState("");
  const [address, setAddress] = useState(null);
  const [contracts, setContracts] = useState([]);
  const [contract, setContract] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [order, setOrder] = useState({});
  const [existOrder, setExistOrder] = useState("");
  const [error, setError] = useState("");
  const [dateDelivery, setDateDelivery] = useState("");

  const { cart } = useCart();

  const orderValid = () => {
    if (deliveryType?.value === 2 || deliveryType?.value === 3) {
      if (customer?.value && deliveryType?.value && address?.value) {
        return true;
      } else {
        toast.error("Должны быть заполнены: клиент, тип доставки");
      }
    } else {
      if (customer?.value && deliveryType?.value) {
        return true;
      } else {
        toast.error("Должны быть заполнены: клиент, тип доставки");
      }
    }
    // if (dateDelivery && dateDeliveryValidate(dateDelivery)) {
    //   return true;
    // }
    return false;
  };

  const contractsMap = (res) =>
    res
      .filter((item) => item.active === true)
      .map((item) => {
        return { value: item.id, label: item.label, main: item.main };
      });

  const accountsMap = (res) =>
    res.map((item) => {
      return { value: item.id, label: item.title || "Главное подразделение" };
    });

  useEffect(() => {
    setAddresses([]);
    setDeliveryType(null);

    if (!customer) return;
    getAddresses(customer?.value)
      .then((res) => {
        if (res.length) {
          setAddresses(addressesMap(res));

          setDeliveryType(DELIVERY.find((v) => v.value === 2));
          return;
        }

        setDeliveryType(DELIVERY.find((v) => v.value === 1));
      })
      .finally(() => setLoading(false));

    getContracts(customer?.value)
      .then((res) => {
        setContracts(contractsMap(res));
        const contract = res.find((v) => v.main === true && v.active === true);
        if (contract) {
          setContract({ value: contract?.id, label: contract?.name });
        }
      })
      .finally(() => setLoading(false));

    getAccounts(customer?.value)
      .then((res) => {
        setAccount({ value: res[0].id, label: res[0].title || "Главное подразделение" });
        if (res.length < 2) {
          setAccounts([]);
          return;
        };
        setAccounts(accountsMap(res));

      })
      .finally(() => setLoading(false));
  }, [customer]);

  const deliveryTypeHandler = (deliveryType) => {
    setDeliveryType(deliveryType);

    if (deliveryType?.value === 1) return;
    setAddress(addresses.find((address) => address.main) || addresses[0]);
  };

  const addressHandler = (address) => {
    setAddress(address);
    setDeliveryType(DELIVERY.find((v) => v.value === (address?.transportCompany ? 3 : 2)));
  }

  // const dateDeliveryValidate = (val) => {
  //   const now = new Date();
  //   if (now.getHours() > 10 && deliveryType?.value === 2) {
  //     if (!moment().isBefore(val)) {
  //       toast.error(`Дата не должна быть меньше ${moment().add(1, 'd').format("DD.MM.Y")}`);
  //       return false;
  //     }
  //   } else {
  //     if (!moment().add(-1, 'd').isBefore(val)) {
  //       toast.error(`Дата не должна быть меньше ${moment().format("DD.MM.Y")}`);
  //       return false;
  //     }
  //   }
  //   return true;
  // }

  const dateDeliveryHandler = (val) => {
    // if (!dateDeliveryValidate(val)) {
    //   return;
    // }
    setDateDelivery(val);
  }

  const orderHandler = () => {
    const noFilledProviders = cart.filter((val) => !val.provider);
    if (noFilledProviders.length) {
      setError(
        `Не заполнены поставщики у ${noFilledProviders.length} позиций в корзине`
      );
      return;
    }

    if (!orderValid()) {
      return false;
    }
    setLoading(true);

    createOrder({
      customer: customer?.value,
      account: account?.value,
      deliveryType: deliveryType?.value,
      address: address?.value,
      comment,
      contract: contract?.value,
      dateDelivery: dateFormat(dateDelivery)
    })
      .then((res) => {
        setOpenModal(true);
        setDeliveryType(null);
        setAddress(null);
        setCustomer(null);
        setContracts([]);
        setContract(null);
        setOrderSuccess(true);
        setOrder(res);
        setAccounts([]);
        localStorage.removeItem("customer");
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  const orderExistHandler = () => {
    const noFilledProviders = cart.filter((val) => !val.provider);
    if (noFilledProviders.length) {
      setError(
        `Не заполнены поставщики у ${noFilledProviders.length} позиций в корзине`
      );
      return;
    }

    setLoading(true);
    createOrder({
      number: existOrder,
    })
      .then((res) => {
        if (res.duplicate) {
          alert(res.duplicate);
        }

        setOpenModal(true);
        setOrderSuccess(true);
        setOrder(res);
        setAccounts([]);
        localStorage.removeItem("customer");
      })
      .catch((e) => toast.error(e.response?.data?.message))
      .finally(() => setLoading(false));
  };

  const setAddressesHandler = (res) => {
    setAddresses(addressesMap(res));
    setAddress({ label: res[0].address, value: res[0].id });
  };

  return (
    <>
      <Card className="orderform">
        {loading && <MySpinner overlay />}
        {error && <Alert color="danger">{error}</Alert>}

        <CardBody>
          <h3 className="mb-4">Оформление заказа</h3>
          <Row>
            <Col lg="3">
              <SelectCustomer
                label={true}
                customer={customer}
                setCustomer={setCustomer}
                getAndSetPrice={getAndSetPrice}
                className="mb-3"
              />
              {accounts.length > 1 &&
                <FormGroup>
                  <Label>Подразделение</Label>
                  <Select
                    placeholder="Выберите подразделение"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={accounts}
                    onChange={setAccount}
                    value={account}
                    isSearchable={false}
                  />
                </FormGroup>
              }

            </Col>
            <Col lg="3">
              <FormGroup>
                <Label>Способ доставки</Label>
                <Select
                  placeholder="Выберите способ доставки"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={DELIVERY}
                  onChange={(deliveryType) => deliveryTypeHandler(deliveryType)}
                  value={deliveryType}
                  isSearchable={false}
                  isDisabled={customer?.value ? false : true}
                />
              </FormGroup>

              {deliveryType?.value !== 1 && (
                <>
                  <FormGroup>
                    <Label>Выберите адрес доставки</Label>
                    <div className="d-flex justify-content-between select_address">
                      <Select
                        placeholder="Выберите адрес доставки"
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={addresses}
                        onChange={(address) => addressHandler(address)}
                        value={address}
                      />
                      <CreateAddressModal
                        customer={{
                          id: customer?.value,
                          name: customer?.label,
                        }}
                        setAddresses={(res) => setAddressesHandler(res)}
                      />
                    </div>
                  </FormGroup>
                </>
              )}
              <FormGroup>
                <Label>Дата {deliveryType?.value === 1 ? `самовывоза` : `доставки`}</Label>
                <div className="d-flex justify-content-between">
                  <DatePicker
                    placeholderText="__.__.____"
                    selected={dateDelivery}
                    onChange={dateDeliveryHandler}
                    dateFormat="dd.MM.yyyy"
                    locale="ru"
                    className="form-control date"
                    customInput={<ReactInputMask mask={"99.99.9999"} />}
                  />
                </div>
              </FormGroup>
            </Col>
            <Col lg="3">
              <FormGroup>
                <Label>Комментарий</Label>
                <Input
                  type="textarea"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  placeholder="Комментарий к заказу"
                />
              </FormGroup>
            </Col>
            <Col lg="3">
              <FormGroup>
                <Label>Договор</Label>
                <Select
                  placeholder="Выберите договор"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={contracts}
                  onChange={setContract}
                  value={contract}
                  isSearchable={false}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="3">
              <Button
                color="primary"
                onClick={orderHandler}
                className="button-wide"
              >
                Создать заказ
              </Button>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <InputGroup className="mt-3">
                <Input
                  value={existOrder}
                  onChange={(e) => setExistOrder(e.target.value)}
                  placeholder="Номер заказа"
                />
                <Button color="secondary" onClick={() => orderExistHandler()}>
                  Добавить в существующий заказ
                </Button>
              </InputGroup>
            </Col>
          </Row>
        </CardBody>
      </Card >

      <Modal isOpen={openModal} toggle={() => setOpenModal(false)}>
        <ModalHeader>
          <div className="modal-title-text">Заказ</div>
          <CloseButton onClick={() => setOpenModal(false)} />
        </ModalHeader>
        <ModalBody className="text-center m-3">
          {existOrder ? (
            <h3 className="mb-1">Заказ № {existOrder} успешно обновлен.</h3>
          ) : (
            <>
              <h3 className="mb-1">Заказ успешно создан!</h3>
            </>
          )}

          <div className="d-flex justify-content-center">
            <Button
              onClick={() => setOpenModal(false)}
              className="mr-3"
              color="secondary"
            >
              Закрыть
            </Button>
            {order?.id && (
              <Link
                className="btn btn-primary"
                to={`/sales/orders/${order.id}`}
                color="primary"
              >
                Перейти в заказ
              </Link>
            )}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
