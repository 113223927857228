import {
  Package as PackageIcon,
  PlusCircle as PlusIcon,
  Printer as PrinterIcon,
  UploadCloud as DiadocIcon,
  Repeat as repeatIcon,
  Briefcase as BriefcaseIcon,
  BarChart2 as BarChart2Icon,
} from "react-feather";

import { SignIn } from "../pages/SignIn";
import { LabelsPage } from "pages/labels";
import { DiadocPage } from "pages/diadoc";
import { ConvertUpdPage } from "pages/convertUpd";
import { ProvidersPage } from "pages/providers";
import { ProviderOnePage } from "pages/providerOne";
import { DashboardPage } from "pages/dashboard";
import { DashboardOldPage } from "pages/dashboardOld";
import { SuppliesPage } from "pages/supplies";
import { SupplyOnePage } from "pages/supplyOne";
import { EnteringBalancePage } from "pages/enteringBalance/EnteringBalance";
import { EnteringBalanceOnePage } from "pages/enteringBalanceOne/EnteringBalanceOne";
import { MovingPage } from "pages/moving/Moving";
import { MovingOnePage } from "pages/movingOne/MovingOne";
import { StockPage } from "pages/stock/Stock";
import { OrdersPickingPage } from "pages/orderPicking";
import { OrdersPickingOnePage } from "pages/orderPickingOne";
import { WriteOffOnePage } from "pages/writeOffOne/WriteOffOne";
import { WriteOffsPage } from "pages/writeOffs/WriteOffs";
import { MisgradingsPage } from "pages/misgradings/Misgradings";
import { MisgradingOnePage } from "pages/misgradingOne/MisgradingOne";
import { AnaliticsPricePage } from "pages/analiticsPrice/AnaliticsPrice";
import { AnaliticsStockPage } from "pages/analiticsStock/AnaliticsStock";
import { SalaryReportPage } from "pages/salaryReport/SalaryReport";
import { AnaliticsSalePage } from "pages/analiticsSale/AnaliticsSale";
import { AnaliticsReceivablesPage } from "pages/analiticsReceivables/AnaliticsReceivables";
import { AcceptanceActsPage } from "pages/acceptanceActs/AcceptanceActs";
import { AcceptanceActOnePage } from "pages/acceptanceActOne/AcceptanceActOne";
import { OrdersToProviderPage } from "pages/ordersToProvider";
import { OrderToProviderOnePage } from "pages/orderToProviderOne";
import { PriceListsPage } from "pages/priceLists";
import { PriceListOnePage } from "pages/priceListOne";
import { StoreKeeperPage } from "pages/storeKeeperReport";
import { DeliveryListPage } from "pages/deliveryList";
import { referenceMenu, referenceRoutes, references } from "./reference";
import { ReturnsToProviderPage } from "pages/returnsToProvider/ReturnsToProvider";
import { ReturnToProviderOnePage } from "pages/returnToProviderOne/ReturnToProviderOne";
import { salesMenu, salesRoutes } from "./sales";

// Routes

const dashboardOldRoutes = {
  path: "/createOrderOld",
  name: "Оформ. заказа",
  title: "Оформление заказа",
  icon: PlusIcon,
  containsHome: true,
  component: DashboardOldPage,
};

const dashboardRoutes = {
  path: "/",
  name: "Оформ. заказа",
  title: "Оформление заказа",
  icon: PlusIcon,
  containsHome: true,
  component: DashboardPage,
};

const providersRoutes = {
  path: "/receipt/providers",
  name: "Поставщики",
  title: "Поставщики",
  icon: PackageIcon,
  component: ProvidersPage,
};

const providerNewRoutes = {
  path: "/receipt/providers/new",
  name: "Новый Поставщик",
  title: "Новый Поставщик",
  component: ProviderOnePage,
};

const providerOneRoutes = {
  path: "/receipt/providers/:id",
  name: "Поставщик",
  component: ProviderOnePage,
};

const supplyRoutes = {
  path: "/receipt/supply",
  name: "Поступления",
  title: "Поступления",
  icon: PackageIcon,
  component: SuppliesPage,
};

const supplyNewRoutes = {
  path: "/receipt/supply/new",
  name: "Новое поступление",
  title: "Новое поступление",
  component: SupplyOnePage,
};

const supplyOneRoutes = {
  path: "/receipt/supply/:id",
  name: "Поступление",
  component: SupplyOnePage,
};

const returnsToProvidersRoutes = {
  path: "/receipt/returnsToProvider",
  name: "Возвраты поставщику",
  title: "Возвраты поставщику",
  icon: PackageIcon,
  component: ReturnsToProviderPage
};

const returnsToProvidersNewRoutes = {
  path: "/receipt/returnsToProvider/new",
  name: "Новый возврат поставщику",
  title: "Новый возврат поставщику",
  component: ReturnToProviderOnePage,
};

const returnsToProvidersOneRoutes = {
  path: "/receipt/returnsToProvider/:id",
  name: "Возврат поставщику",
  component: ReturnToProviderOnePage,
};

const acceptanceActsRoutes = {
  path: "/receipt/acceptanceActs",
  name: "Акты приема передачи на хранение",
  title: "Акты приема передачи на хранение",
  component: AcceptanceActsPage,
};

const acceptanceActNewRoutes = {
  path: "/receipt/acceptanceActs/new",
  name: "Новый акт приема передачи на хранение",
  component: AcceptanceActOnePage,
};

const acceptanceActOneRoutes = {
  path: "/receipt/acceptanceActs/:id",
  name: "Акт приема передачи на хранение",
  component: AcceptanceActOnePage,
};

const ordersToProviderRoutes = {
  path: "/receipt/ordersToProvider",
  name: "Заказы поставщику",
  title: "Заказы поставщику",
  component: OrdersToProviderPage,
};

const orderToProviderNewRoutes = {
  path: "/receipt/ordersToProvider/new",
  name: "Новый заказ поставщику",
  component: OrderToProviderOnePage,
};

const orderToProviderOneRoutes = {
  path: "/receipt/ordersToProvider/:id",
  name: "Заказ поставщику",
  component: OrderToProviderOnePage,
};

const priceListsRoutes = {
  path: "/receipt/priceLists",
  name: "Прайс листы",
  title: "Прайс листы",
  icon: PackageIcon,
  component: PriceListsPage,
};

const priceListNewRoutes = {
  path: "/receipt/priceLists/new",
  name: "Новый прайс лист",
  title: "Новый прайс лист",
  component: PriceListOnePage,
};

const priceListOneRoutes = {
  path: "/receipt/priceLists/:id",
  name: "Новый прайс лист",
  component: PriceListOnePage,
};

const enteringBalanceRoutes = {
  path: "/stock/enteringBalance",
  name: "Ввод остатков",
  title: "Ввод остатков",
  icon: PackageIcon,
  component: EnteringBalancePage,
};

const enteringBalanceNewRoutes = {
  path: "/stock/enteringBalance/new",
  name: "Новый ввод остатков",
  title: "Новый ввод остатков",
  component: EnteringBalanceOnePage,
};

const enteringBalanceOneRoutes = {
  path: "/stock/enteringBalance/:id",
  name: "Ввод остатков",
  component: EnteringBalanceOnePage,
};

const misgradingsRoutes = {
  path: "/stock/misgradings",
  name: "Пересортицы",
  title: "Пересортицы",
  icon: PackageIcon,
  component: MisgradingsPage,
};

const misgradingNewRoutes = {
  path: "/stock/misgradings/new",
  name: "Новая пересортица",
  title: "Новая пересортица",
  component: MisgradingOnePage,
};

const misgradingOneRoutes = {
  path: "/stock/misgradings/:id",
  name: "Пересортица",
  component: MisgradingOnePage,
};

const movingRoutes = {
  path: "/stock/moving",
  name: "Перемещения товаров",
  title: "Перемещения товаров",
  icon: PackageIcon,
  component: MovingPage,
};

const movingNewRoutes = {
  path: "/stock/moving/new",
  name: "Новое перемеение товаров",
  title: "Новое перемещение товаров",
  component: MovingOnePage,
};

const movingOneRoutes = {
  path: "/stock/moving/:id",
  name: "Перемещение товаров",
  component: MovingOnePage,
};

const stockPageRoutes = {
  path: "/stock/stock",
  name: "Движения товаров",
  title: "Движения товаров",
  component: StockPage,
};

const writeOffNewRoutes = {
  path: "/stock/writeOffs/new",
  name: "Новое списание товаров",
  title: "Новое списание товаров",
  component: WriteOffOnePage,
};

const writeOffOneRoutes = {
  path: "/stock/writeOffs/:id",
  name: "Списание товаров",
  component: WriteOffOnePage,
};

const writeOffsPageRoutes = {
  path: "/stock/writeOffs",
  name: "Списания товаров",
  title: "Списания товаров",
  component: WriteOffsPage,
};

const signInRoutes = {
  path: "/sign-in",
  name: "Логин",
  title: "Логин",
  component: SignIn,
};

const labelsRoutes = {
  path: "/labels",
  name: "Печать этикеток",
  title: "Печать этикеток",
  icon: PrinterIcon,
  component: LabelsPage,
};

const diadocRoutes = {
  path: "/diadoc/",
  name: "Выгрузка в Диадок",
  title: "Выгрузка в Диадок",
  icon: DiadocIcon,
  component: DiadocPage,
};

const diadocRoutesByOrder = {
  path: "/diadoc/:id",
  name: "Выгрузка в Диадок",
  icon: DiadocIcon,
  component: DiadocPage,
};


const convertUpdRoutes = {
  path: "/convertUpd",
  name: "Конвертация УПД",
  title: "Конвертация УПД",
  component: ConvertUpdPage,
};

const orderPickingRoute = {
  path: "/stock/orderPicking",
  name: "Заявки на сборку",
  title: "Заявки на сборку",
  icon: repeatIcon,
  component: OrdersPickingPage,
};

const orderPickingOneRoute = {
  path: "/stock/orderPicking/:id",
  name: "Заявка на сборку",
  icon: repeatIcon,
  component: OrdersPickingOnePage,
};

const analiticsPriceRoute = {
  path: "/analitics/analiticsPrice",
  name: "Аналитика цен поставщиков",
  title: "Аналитика цен поставщиков",
  icon: repeatIcon,
  component: AnaliticsPricePage,
};

const analiticsStockRoute = {
  path: "/analitics/analiticsStock",
  name: "Аналитика остатков склада",
  title: "Аналитика остатков склада",
  icon: repeatIcon,
  component: AnaliticsStockPage,
};

const salaryReportRoute = {
  path: "/analitics/salaryReport",
  name: "Отчет по зарплате",
  title: "Отчет по зарплате",
  icon: repeatIcon,
  component: SalaryReportPage,
};

const storeKeeperReportRoute = {
  path: "/analitics/storeKeeperReport",
  name: "Отчет по кладовщикам",
  title: "Отчет по кладовщикам",
  icon: repeatIcon,
  component: StoreKeeperPage,
};

const analiticsSaleRoute = {
  path: "/analitics/analiticsSale",
  name: "Отчет по продажам",
  title: "Отчет по продажам",
  component: AnaliticsSalePage,
};

const analiticsReceivablesRoute = {
  path: "/analitics/analiticsReceivables",
  name: "Аналитика дебиторской задолженности",
  title: "Аналитика дебиторской задолженности",
  component: AnaliticsReceivablesPage,
};

const analiticsRoutes = {
  path: "/analitics",
  name: "Отчеты",
  title: "Отчеты",
  icon: BarChart2Icon,
  children: [
    analiticsPriceRoute,
    analiticsStockRoute,
    salaryReportRoute,
    analiticsSaleRoute,
    analiticsReceivablesRoute,
    storeKeeperReportRoute,
  ],
};

const receiptRoutes = {
  path: "/receipt",
  name: "Закупки",
  title: "Закупки",
  icon: BriefcaseIcon,
  children: [
    supplyRoutes,
    providersRoutes,
    returnsToProvidersRoutes,
    acceptanceActsRoutes,
    ordersToProviderRoutes,
    priceListsRoutes,
  ],
};

const stockRoutes = {
  path: "/stock",
  name: "Склад",
  title: "Склад",
  icon: PackageIcon,
  children: [
    movingRoutes,
    enteringBalanceRoutes,
    stockPageRoutes,
    orderPickingRoute,
    writeOffsPageRoutes,
    misgradingsRoutes,
  ],
};

// Dashboard specific routes
export const dashboard = [
  dashboardOldRoutes,
  dashboardRoutes,
  ...salesRoutes,
  labelsRoutes,
  diadocRoutes,
  diadocRoutesByOrder,
  convertUpdRoutes,
  providersRoutes,
  providerNewRoutes,
  providerOneRoutes,
  ...referenceRoutes,
  supplyRoutes,
  supplyNewRoutes,
  supplyOneRoutes,
  enteringBalanceRoutes,
  enteringBalanceNewRoutes,
  enteringBalanceOneRoutes,
  returnsToProvidersRoutes,
  returnsToProvidersNewRoutes,
  returnsToProvidersOneRoutes,
  movingRoutes,
  movingNewRoutes,
  movingOneRoutes,
  stockPageRoutes,
  orderPickingRoute,
  orderPickingOneRoute,
  writeOffNewRoutes,
  writeOffOneRoutes,
  writeOffsPageRoutes,
  misgradingsRoutes,
  misgradingNewRoutes,
  misgradingOneRoutes,
  analiticsRoutes,
  acceptanceActNewRoutes,
  acceptanceActOneRoutes,
  acceptanceActsRoutes,
  ordersToProviderRoutes,
  orderToProviderNewRoutes,
  orderToProviderOneRoutes,
  priceListsRoutes,
  priceListNewRoutes,
  priceListOneRoutes,
];

// Auth specific routes
export const page = [signInRoutes];

// All routes
//dashboardRoutes
const exportDefault = [
  dashboardRoutes,
  salesMenu,
  receiptRoutes,
  stockRoutes,
  referenceMenu,
  analiticsRoutes,
  labelsRoutes,
  diadocRoutes,
  convertUpdRoutes,
];
export default exportDefault;
