import { AutocompleteArticle } from "features/Dashboard/AutocompleteArticle";
import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

import { validHandler } from "utils/validation";

import { CreateNomenclatureModal } from "components/CreateNomenclatureModal";
import { CloseButton } from "components/CloseButton";
import { addEnteringBalanceProduct } from "services/apiSupply";
import { toast } from "react-toastify";
import { MySpinner } from "components/MySpinner";

export const AddProductModal = ({
  id,
  openModal,
  setOpenModal,
  setProducts,
  getAndSetEnteringBalance
}) => {
  const [article, setArticle] = useState("");
  const [brand, setBrand] = useState("");
  const [name, setName] = useState("");
  const [count, setCount] = useState("");
  const [price, setPrice] = useState("");
  const [loading, setLoading] = useState(false);
  const [nomenclature, setNomenclature] = useState(null);
  const [provider, setProvider] = useState({ label: "", value: "" });
  const [errors, setErrors] = useState([]);
  const [needValid, setNeedValid] = useState(false);

  const countInputHandler = (e) => {
    const count = e.target.value;
    if (count === "" || /^[0-9\b]+$/.test(count)) {
      setCount(count);
    }
  };

  const priceInputHandler = (e) => {
    const count = e.target.value;
    if (count === "" || /^[0-9\b]+$/.test(count)) {
      setPrice(count);
    }
  };

  const addProductHandler = () => {
    setNeedValid(true);

    const { valid, errors } = validHandler({
      nomenclature,
      count,
    });

    setErrors(errors);

    if (!valid) return;

    if (!id) {
      setProducts((prev) => [
        ...prev,
        {
          index: !prev.length ? 1 : prev.length + 1,
          id: !prev.length ? 1 : prev.length + 1,
          nomenclature: {
            id: nomenclature,
            article,
            brand,
            name,
          },
          count: parseInt(count),
          price,
        },
      ]);

      setArticle("");
      setBrand("");
      setName("");
      setCount("");
      setPrice("");
      setProvider("");
      setOpenModal(false);
      setNeedValid(false);
    } else {

      setLoading(true);
      addEnteringBalanceProduct({
        id,
        count: parseInt(count),
        price,
        nomenclature: {
          id: nomenclature
        }
      })
        .then((res) => {
          setArticle("");
          setBrand("");
          setName("");
          setCount("");
          setPrice("");
          setProvider("");
          setOpenModal(false);
          setNeedValid(false);
          setProducts(res.products);
          getAndSetEnteringBalance();
        })
        .catch((e) => {
          toast.error(e.response?.data?.message);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    const { errors } = validHandler({
      nomenclature,
      count,
      price,
    });
    setErrors(errors);
    // eslint-disable-next-line
  }, [article, brand, name, count, price]);

  const selectedBrand = (brand) => {
    setBrand(brand?.name);
  };

  return (
    <Modal isOpen={openModal} toggle={() => setOpenModal(false)}>
      <ModalHeader>
        <div className="modal-title-text">Добавить товар</div>
        <CloseButton onClick={() => setOpenModal(false)} />
      </ModalHeader>
      <ModalBody className="m-3">

        {loading && <MySpinner overlay />}
        <Row>
          <Col lg="6">
            <FormGroup>
              <Label>Артикул</Label>
              <ButtonGroup style={{ width: "100%" }}>
                <AutocompleteArticle
                  article={article}
                  brand={brand}
                  setArticle={setArticle}
                  setBrand={({ id, name }) => setBrand(name)}
                  setName={setName}
                  setNomenclature={setNomenclature}
                  needValid={needValid}
                  errors={errors}
                  style={{ width: "218px" }}
                />
                <CreateNomenclatureModal
                  articleHandler={setArticle}
                  brandHandler={selectedBrand}
                  nameHandler={setName}
                  nomenclatureHandler={setNomenclature}
                />
              </ButtonGroup>
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Label>Бренд</Label>
              <Input
                name="brand"
                placeholder="Бренд"
                value={brand || ""}
                invalid={needValid && errors.hasOwnProperty("brand")}
                readOnly
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup>
              <Label>Наименование</Label>
              <Input
                name="name"
                placeholder="Наименование"
                onChange={(e) => setName(e.target.value)}
                value={name || ""}
                invalid={needValid && errors.hasOwnProperty("name")}
                readOnly
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="3">
            <FormGroup>
              <Label>Кол-во</Label>
              <Input
                name="count"
                placeholder="Кол-во"
                onChange={countInputHandler}
                value={count || ""}
                invalid={needValid && errors.hasOwnProperty("count")}
              />
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <Label>Цена</Label>
              <Input
                name="price"
                placeholder="Цена"
                onChange={priceInputHandler}
                value={price || ""}
                invalid={needValid && errors.hasOwnProperty("price")}
              />
            </FormGroup>
          </Col>
        </Row>

        <div className="d-flex justify-content-between">
          <Button onClick={() => setOpenModal(false)} color="secondary">
            Закрыть
          </Button>
          <Button onClick={addProductHandler} color="primary">
            Добавить
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};
