import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import InputMask from "react-input-mask";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";
import { CheckCircle } from "react-feather";

import { HeaderPage } from "components/HeaderPage";
import { MySpinner } from "components/MySpinner";
import { EnteringBalanceProductTable } from "features/EnteringBalance/EnteringBalanceProductTable";
import {
  carryOutEnteringBalance,
  createEnteringBalance,
  getEnteringBalanceOne,
  updateEnteringBalance,
} from "services/apiSupply";
import { dateFormat, dateTimeFormat } from "utils/format";

import "./enteringBalanceOne.scss";
import { getWarehouses } from "services/apiWarehouse";

export const EnteringBalanceOnePage = () => {
  const history = useHistory();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [isNew, setIsNew] = useState(!id ? true : false);
  const [number, setNumber] = useState("");
  const [date, setDate] = useState("");
  const [carriedOut, setCarriedOut] = useState(false);
  const [comment, setComment] = useState("");
  const [admin, setAdmin] = useState({});
  const [warehouses, setWarehouses] = useState([]);
  const [warehouse, setWarehouse] = useState(null);
  const [products, setProducts] = useState([]);

  const getAndSetWarehouses = () => {
    getWarehouses()
      .then(setWarehouses)
      .catch((e) => console.error(e));
  };

  const getAndSetEnteringBalance = () => {
    getEnteringBalanceOne(id)
      .then((res) => {
        setComment(res.comment);
        setNumber(res.number);
        setProducts(res.products);
        setDate(dateTimeFormat(res.date));
        setWarehouse({ label: res.warehouse?.name, value: res.warehouse?.id });
        setCarriedOut(res.carriedOut);
        setAdmin(res.admin);
      })
      .catch((e) => toast.error(e.response?.data?.message))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getAndSetWarehouses();
    if (!id) return;
    setLoading(true);
    getAndSetEnteringBalance();
  }, []);

  useEffect(() => {
    if (!id && warehouses.length) {
      setWarehouse({ label: warehouses[0].name, value: warehouses[0].id });
    }
  }, [warehouses]);

  const createEnteringBalanceHandler = () => {
    createEnteringBalance({
      comment,
      warehouse: warehouse.value,
      products,
      date,
    })
      .then((res) => {
        history.push(res.id);
        toast.success("Ввод остатков успешно создан");
      })
      .catch((e) => toast.error(e.response?.data?.message))
      .finally(() => setLoading(false));
  };

  const updateEnteringBalanceHandler = () => {
    setLoading(true);
    updateEnteringBalance({
      id,
      comment,
      warehouse: warehouse.value,
      date,
    })
      .then((res) => {
        setComment(res.comment);
        setWarehouse({ label: res.warehouse?.name, value: res.warehouse?.id });
        toast.success("Ввод остатков успешно сохранен");
      })
      .catch((e) => toast.error(e.response?.data?.message))
      .finally(() => setLoading(false));
  };

  const carryOutHandler = () => {
    setLoading(true);
    carryOutEnteringBalance({ id })
      .then((res) => {
        setCarriedOut(res.carriedOut);
        toast.success("Ввод остатков успешно проведен");
      })
      .catch((e) => toast.error(e.response?.data?.message))
      .finally(() => setLoading(false));
  };

  const renderTitle = () => {
    if (isNew) {
      return `Новый ввод остатков`;
    }

    return `Ввод остатков № ${number || ""}`;
  };

  return (
    <Container fluid className="p-0 entering-balance-one">
      <Helmet>
        <title>{renderTitle()}</title>
      </Helmet>
      {loading && <MySpinner overlay />}

      <HeaderPage title={renderTitle()}>
        <>
          {!isNew && (
            <>
              <div className="entering-balance-one__date-block">
                <Label>Дата</Label>
                <InputMask
                  id="date"
                  name="date"
                  type="text"
                  onChange={(e) => setDate(e.target.value)}
                  value={date}
                  mask={"99.99.9999 99:99:99"}
                  className="form-control date"
                />
              </div>

              <div className="entering-balance-one__carried-out-button">
                {carriedOut && <CheckCircle color="green" />}

                <Button
                  color="primary"
                  onClick={carryOutHandler}
                  className="button-wide ml-1"
                >
                  {carriedOut ? `Распровести` : `Провести`}
                </Button>
              </div>
            </>
          )}
        </>
      </HeaderPage>

      <Card className="entering-balance-one__form mb-0">
        <CardBody className="pb-3">
          <Row>
            <Col>
              <Label className="text-sm-right">Комментарий</Label>
              <Input
                type="textarea"
                name="comment"
                placeholder="Комментарий"
                value={comment || ""}
                onChange={(e) => setComment(e.target.value)}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Label className="text-sm-right">Склад</Label>
              <Select
                name="warehouse"
                placeholder="Склад"
                className="react-select-container"
                classNamePrefix="react-select"
                options={warehouses.map((item) => {
                  return {
                    label: item.name,
                    value: item.id,
                  };
                })}
                value={warehouse}
                onChange={setWarehouse}
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Label className="text-sm-right">Создал</Label>
            </Col>
            <Col md={9}><b>{admin.name}</b></Col>
          </Row>

          {!isNew && (
            <Row>
              <Col>
                <Button
                  color="primary"
                  onClick={updateEnteringBalanceHandler}
                  className="button-wide mr-3"
                >
                  Сохранить
                </Button>
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>

      <EnteringBalanceProductTable
        id={id}
        products={products}
        setProducts={setProducts}
        getAndSetEnteringBalance={getAndSetEnteringBalance}
        carriedOut={carriedOut}
      />

      {products.length > 0 && (
        <Card>
          <CardBody>
            {isNew && (
              <Button
                color="primary"
                onClick={createEnteringBalanceHandler}
                className="button-wide"
              >
                Создать ввод остатков
              </Button>
            )}
          </CardBody>
        </Card>
      )}
    </Container>
  );
};
