import React, { useEffect, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";

import { ROUTES } from "features/Users/Addresses";

import "./changeRoute.scss";
import { updateDelivery, updateOrder } from "services/api";


export const ChangeRoute = ({
  row,
  getAndSetDeliveryList,
  setLoading
}) => {
  const [route, setRoute] = useState(null);

  const routeHandler = (val) => {
    setRoute(val);
    setLoading(true);
    updateDelivery({
      id: row.id,
      route: val.value,
    })
      .then((res) => {
        getAndSetDeliveryList();
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
        setLoading(false);
      });
  };

  return (
    <>
      <span>{ROUTES.find(v => v.value === row.route)?.shortName}</span>
      <Select
        name="route"
        placeholder="Выберите маршрут"
        className="react-select-container changeRoute"
        classNamePrefix="react-select"
        options={ROUTES}
        value={route}
        onChange={routeHandler}
      />
    </>
  );
};
