import React, { useEffect, useState } from "react";
import cellEditFactory from "react-bootstrap-table2-editor";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import { Cut } from "components/Cut";
import { ChangeWarehouse } from "../ChangeWarehouse";
import {
  getWarehouses,
  removeMisgradingProduct,
  updateMisgradingProduct,
} from "services/apiWarehouse";

import "./misgradingProductTable.scss";
import { RemoveButton } from "../RemoveButton";
import { MySpinner } from "components/MySpinner";
import { BarChart } from "components/BarChart";
import { existRender } from "utils/existRender";

export const MisgradingProductTable = ({
  products,
  setProducts,
  loading,
  setLoading,
  carriedOut,
}) => {
  const [warehouses, setWarehouses] = useState([]);

  const productHandler = (oldData, newData, row) => {
    if (String(oldData) === String(newData)) return;
    setLoading(true);
    updateMisgradingProduct({
      id: row.id,
      count: row.count,
    })
      .then((res) => {
        setProducts(res.products);
        toast.success("Товар успешно изменен");
      })
      .catch((e) => toast.error(e.response?.data?.message))
      .finally(() => setLoading(false));
  };

  const removeHandler = (id) => {
    if (!window.confirm("Вы действительно хотите удалить товар?")) {
      return;
    }
    setLoading(true);
    removeMisgradingProduct({ id })
      .then((res) => {
        setProducts(res.products);
        toast.success("Товар успешно удален");
      })
      .catch((e) => toast.error(e.response?.data?.message))
      .finally(() => setLoading(false));
  };

  const getAndSetWarehouses = () => {
    getWarehouses()
      .then(setWarehouses)
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    getAndSetWarehouses();
  }, []);

  const tableProductsColumns = [
    {
      dataField: "index",
      text: "№",
      headerStyle: (colum, colIndex) => {
        return { width: "2%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "articleOut",
      text: "Артикул 1",
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
      formatter: (cell, row) => row.nomenclatureOut?.article,
      editable: false,
      footer: () => null,
    },
    {
      dataField: "brandOut",
      text: "Бренд 1",
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
      formatter: (cell, row) => row.nomenclatureOut?.brand,
      editable: false,
      footer: () => null,
    },
    {
      dataField: "nameOut",
      text: "Наименование 1",
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      formatter: (cell, row) => <Cut>{row.nomenclatureOut?.name}</Cut>,
      editable: false,
      footer: () => null,
    },
    {
      dataField: "inStock",
      text: "Ост.",
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
      formatter: (cell, row) => (
        <div className="warehouse-container">
          {(cell || row.inStockAll) && (
            <BarChart
              color={cell > 0 ? "green" : "red"}
              count={existRender(row)}
            />
          )}
        </div>
      ),
      headerClasses: () => "right",
      classes: (cell) => "right",
      footer: () => null,
      editable: false,
    },
    {
      dataField: "count",
      text: "Кол-во",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      headerClasses: () => "right",
      footerClasses: () => "right",
      classes: (cell, row) => "editable right",
      validator: (newValue, row) => {
        if (isNaN(newValue)) {
          return {
            valid: false,
            message: "Должны быть только цифры",
          };
        }
        if (newValue < 0) {
          return {
            valid: false,
            message: "Кол-во не может быть меньше 0",
          };
        }
        return true;
      },
    },
    {
      dataField: "articleIn",
      text: "Артикул 2",
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
      formatter: (cell, row) => row.nomenclatureIn?.article,
      editable: false,
      footer: () => null,
    },
    {
      dataField: "brandIn",
      text: "Бренд 2",
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
      formatter: (cell, row) => row.nomenclatureIn?.brand,
      editable: false,
      footer: () => null,
    },
    {
      dataField: "nameIn",
      text: "Наименование 2",
      headerStyle: (colum, colIndex) => {
        return { width: "15%" };
      },
      formatter: (cell, row) => <Cut>{row.nomenclatureIn?.name}</Cut>,
      editable: false,
      footer: () => null,
    },
    {
      dataField: "warehouse",
      text: "Склад",
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
      formatter: (cell, row) => cell?.name,
      footer: () => null,
      editable: false,
      hidden: !carriedOut,
    },
    {
      dataField: "warehouse1",
      text: "Склад",
      headerStyle: (colum, colIndex) => {
        return { width: "13%" };
      },
      formatter: (cell, row) => (
        <ChangeWarehouse
          row={row}
          warehouses={warehouses}
          setLoading={setLoading}
          setProducts={setProducts}
        />
      ),
      footer: () => null,
      editable: false,
      hidden: carriedOut,
    },
    {
      dataField: "",
      text: "",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      formatter: (cell, row) => (
        <RemoveButton removeHandler={() => removeHandler(row.id)} />
      ),
      editable: false,
      footer: () => null,
      hidden: carriedOut,
    },
  ];

  return (
    <>
      {loading && <MySpinner overlay />}
      <BootstrapTable
        wrapperClasses="misgrading-product"
        keyField="id"
        data={products}
        columns={tableProductsColumns}
        bootstrap4
        bordered={false}
        cellEdit={cellEditFactory({
          mode: "click",
          blurToSave: true,
          afterSaveCell: (oldData, newData, row) =>
            productHandler(oldData, newData, row),
        })}
      />
    </>
  );
};
