import React, { useEffect, useRef, useState } from "react";
import cellEditFactory from "react-bootstrap-table2-editor";
import BootstrapTable from "react-bootstrap-table-next";
import { priceFormat } from "utils/format";
import Select from "react-select";
import { Button, Card, CardBody, Col, Input, Row } from "reactstrap";
import { CornerRightDown as CornerRightDownIcon } from "react-feather";
import classNames from "classnames";
import { Trash2, Edit } from "react-feather";
import { toast } from "react-toastify";

import { MySpinner } from "components/MySpinner";
import { AddProductModal } from "../AddProductModal";
import { SetNomenclatureModal } from "../SetNomenclatureModal";
import { TableButton } from "components/TableButton";
import { validGtd } from "utils/validGtd";
import { Cut } from "components/Cut";
import { updateNomenclature } from "services/api";

import {
  removeSupplyProducts,
  supplyFileImportUpdate,
  supplyFileImportUpload,
  updateSupplyProduct,
  updateSupplyProductsWarehuse,
} from "services/apiSupply";

import "./supplyProductTable.scss";
import { SelectCell } from "../SelectCell";
import { NomenclatureModal } from "features/Nomenclature/NomenclatureModal";
import { BarChart } from "components/BarChart";
import { existRender } from "utils/existRender";
import { SkladPopup } from "features/Orders/SkladPopup";

const TEMPLATES = [
  { label: "Стандартный УПД", value: "standard" },
  { label: "Стандарт Новый", value: "standardNew" },
  { label: "Устаревший УПД", value: "old" },
  { label: "Стандартный УПД 2 вариант", value: "standard2" },
  { label: "Омега", value: "omega" },
  { label: "Шате-М", value: "shate" },
  { label: "Траксервис", value: "trackservice" },
  { label: "Тракснаб", value: "tracksnab" },
  { label: "Амтел", value: "amtel" },
  { label: "Тракмоторс (tabload)", value: "trackmotors" },
  { label: "Тракмоторс 2", value: "trackmotors2" },
  { label: "М-ГРУПП ЛОГИСТИК", value: "groupLogistic" },
  { label: "Столица", value: "stolitsa" },
  { label: "Барт авто", value: "bartauto" },
  { label: "Джипиэн Рус", value: "gpnrus" },
  { label: "Корона СВХ", value: "koronaSvh" },
  { label: "Турция", value: "turkish" },
];

export const SupplyProductTable = ({
  id,
  status,
  products,
  setProducts,
  carriedOut,
  setCarriedOut,
  bailor,
  provider,
  warehouses,
  getAndSetSupply
}) => {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openNomenclatureModal, setOpenNomenclatureModal] = useState(false);
  const [nomenclatureId, setNomenclatureId] = useState(null);
  const inputSupplyFile = useRef(null);
  const [supplyFile, setSupplyFile] = useState(null);
  const [template, setTemplate] = useState(null);
  const [product, setProduct] = useState(null);
  const [warehouse, setWarehouse] = useState(null);

  const removeProductsHandler = (id = null) => {
    if (!window.confirm("Вы действительно хотите удалить выбранные товары?")) {
      return;
    }

    setLoading(true);

    removeSupplyProducts({ ids: id ? [id] : selected })
      .then((res) => {
        setProducts(res.products);
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
        setSelected([]);
      });
  };

  useEffect(() => {
    if (!supplyFile) return;
    setLoading(true);
    const formData = new FormData();
    formData.append("file", supplyFile);
    formData.append("id", id || null);
    formData.append("template", template.value);
    supplyFileImportUpload(formData)
      .then((res) => {
        setProducts(res.products);
      })
      .finally(() => {
        setLoading(false);
        inputSupplyFile.current.value = "";
      });

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplyFile]);

  const updateData = () => {
    supplyFileImportUpdate(products)
      .then((res) => {
        setProducts(res.products);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const productHandler = (oldData, newData, row) => {
    if (String(oldData) === String(newData)) return;
    setLoading(true);

    updateSupplyProduct({
      ...row,
      warehouse: warehouse?.value,
    })
      .then((res) => {
        setProducts(res.products);
      })
      .finally(() => setLoading(false));
  };

  const selectRow = {
    mode: "checkbox",
    bgColor: "#bfbfbf",
    onSelect: (row, isSelect, rowIndex, e) => {
      setSelected((prev) => {
        if (!isSelect) {
          prev.splice(
            prev.findIndex((val) => val === row.id),
            1
          );
          return [...prev];
        }
        return [...prev, row.id];
      });
    },
    onSelectAll: (isSelect, rows, e) => {
      if (!isSelect) {
        setSelected([]);
      } else {
        setSelected(products.map((val) => val.id));
      }
    },
  };

  const importHandler = (val) => {
    setTemplate(val);

    if (inputSupplyFile) {
      inputSupplyFile.current.click();
    }
  };

  const changeWarehouseHandler = () => {
    setLoading(true);
    updateSupplyProductsWarehuse({
      supplyId: id,
      ids: selected,
      warehouse: warehouse?.value,
    })
      .then((res) => {
        setProducts(res.products);
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  const cellHandler = (nomenclature, cell) => {
    setLoading(true);
    updateNomenclature({
      id: nomenclature,
      cell: cell?.value || ""
    })
      .then((res) => {
        toast.success("Ячейка успешно сохранена");
        getAndSetSupply();
      })
      .catch((e) => {
        toast.error(e.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  const tableProductsColumns = [
    {
      dataField: "index",
      text: "№",
      headerStyle: (colum, colIndex) => {
        return { width: "2%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "article",
      text: "Артикул",
      formatter: (cell, row) => <span className="link" onClick={() => {
        setNomenclatureId(row.nomenclature?.id);
        setOpenNomenclatureModal(true);
      }}>{row.nomenclature?.article}</span>,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      editable: false,
      footer: () => null,
      clickToSelect: false,
    },
    {
      dataField: "brand",
      text: "Бренд",
      formatter: (cell, row) => <span className="link" onClick={() => {
        setNomenclatureId(row.nomenclature?.id);
        setOpenNomenclatureModal(true);
      }}>{row.nomenclature?.brand}</span>,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "name",
      text: "Наименование",
      formatter: (cell, row) => <span className="link" onClick={() => {
        setNomenclatureId(row.nomenclature?.id);
        setOpenNomenclatureModal(true);
      }}><Cut>{row.nomenclature?.name}</Cut></span>,
      headerStyle: (colum, colIndex) => {
        return { width: "16%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "nomenclature",
      text: "Номенклатура",
      formatter: (cell, row) => {
        if (row.nomenclature?.id) {
          return (
            <Cut>
              {row.nomenclature?.article}, {row.nomenclature?.brand},{" "}
              {row.nomenclature?.name}
            </Cut>
          );
        } else {
          return (
            <SetNomenclatureModal
              product={row}
              products={products}
              updateData={updateData}
            />
          );
        }
      },
      headerStyle: (colum, colIndex) => {
        return { width: "16%" };
      },
      editable: false,
      footer: () => null,
      hidden: !supplyFile,
    },
    {
      dataField: "count",
      text: "Кол-во",
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
      headerClasses: () => "right",
      footerClasses: () => "right",
      classes: (cell, row) => classNames("right", {
        editable: !carriedOut,
        error: status?.alias === 'inWork' && row.count !== row.factCount,
        success: status?.alias === 'inWork' && row.count === row.factCount
      }),
      footer: (columnData) => {
        const total = columnData.reduce((acc, item) => acc + parseInt(item), 0);
        return total;
      },
      editable: false,
    },
    {
      dataField: "factCount",
      text: "факт",
      headerStyle: (colum, colIndex) => {
        return { width: "5%" };
      },
      headerClasses: () => "right",
      footerClasses: () => "right",
      classes: (cell, row) => classNames("right", {
        editable: !carriedOut,
        error: status?.alias === 'inWork' && row.count !== row.factCount,
        success: status?.alias === 'inWork' && row.count === row.factCount
      }),
      footer: (columnData) => {
        const total = columnData.reduce((acc, item) => acc + parseInt(item), 0);
        return total;
      },
      editable: true,
      hidden: status?.alias !== 'inWork'
    },
    {
      dataField: "cell",
      text: "Ячейка",
      formatter: (cell, row) =>
        <SelectCell row={row} cellHandler={(val) => cellHandler(row.nomenclature?.id, val)} />,
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
      editable: false,
      events: {
        onClick: (e) => {
          e.stopPropagation();
        },
      },
    },
    {
      dataField: "customers",
      text: "Покупатель",
      formatter: (cell, row) => <Cut>{cell}</Cut>,
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
      editable: false,
      footer: () => null
    },
    {
      dataField: "orders",
      text: "Товар в заказах",
      formatter: (cell, row) => <Cut>{cell}</Cut>,
      classes: (cell) => classNames({ "green-bg": cell && !id }),
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
      editable: false,
      footer: () => null
    },
    {
      dataField: "inStock",
      text: "Ост.",
      headerStyle: (colum, colIndex) => {
        return { width: "7%" };
      },
      formatter: (cell, row) => (
        <div className="warehouse-container">
          {(cell || row.inStockAll) && (
            <SkladPopup row={row}>
              <BarChart
                color={cell > 0 ? "green" : "red"}
                count={existRender(row)}
              />
            </SkladPopup>
          )}
        </div>
      ),
      headerClasses: () => "right",
      classes: (cell) => "right",
      footer: () => null,
      editable: false,
    },
    {
      dataField: "ordersToProvider",
      text: "Товар в зак. пост-ку",
      formatter: (cell, row) => <Cut>{cell}</Cut>,
      classes: (cell) => classNames({ "blue-bg": cell }),
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
      editable: false,
      footer: () => null,
      hidden: id,
    },
    {
      dataField: "price",
      text: "Цена",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      formatter: (cell) => priceFormat(cell),
      headerClasses: () => "right",
      classes: () => classNames("right bold"),
      footer: () => null,
      editable: false,
    },
    {
      dataField: "summ",
      text: "Сумма",
      headerStyle: (colum, colIndex) => {
        return { width: "9%" };
      },
      formatter: (cell) => priceFormat(cell),
      headerClasses: () => "right",
      footerClasses: () => "right bold",
      classes: () => "right bold",
      editable: false,
      footer: (columnData) => {
        const total = columnData.reduce(
          (acc, item) => acc + parseFloat(item),
          0
        );
        return total && priceFormat(total);
      },
    },
    {
      dataField: "costPrice",
      text: "Себ-ть",
      headerStyle: (colum, colIndex) => {
        return { width: "8%" };
      },
      formatter: (cell) => priceFormat(cell),
      headerClasses: () => "right",
      classes: () => "right",
      footer: () => null,
      editable: false,
    },
    {
      dataField: "countryCode",
      text: "Код страны",
      headerStyle: (colum, colIndex) => {
        return { width: "6%" };
      },
      footer: () => null,
      editable: !carriedOut,
    },
    {
      dataField: "countryName",
      text: "Страна",
      formatter: (cell, row) => <Cut>{row.countryName}</Cut>,
      headerStyle: (colum, colIndex) => {
        return { width: "10%" };
      },
      editable: false,
      footer: () => null,
    },
    {
      dataField: "gtd",
      text: "ГТД",
      formatter: (cell, row) => (
        <Cut>
          {row.countryCode && !validGtd(row.gtd) ? (
            <span className="error">{row.gtd}</span>
          ) : (
            <>{row.gtd}</>
          )}
        </Cut>
      ),
      headerStyle: (colum, colIndex) => {
        return { width: "18%" };
      },
      footer: () => null,
      editable: !carriedOut,
    },
    {
      dataField: "warehouse",
      text: "Склад",
      formatter: (cell) => <Cut>{cell?.name}</Cut>,
      headerStyle: (colum, colIndex) => {
        return { width: "9%" };
      },
      footer: () => null,
      editable: false,
    },
    {
      dataField: "",
      text: "ред.",
      formatter: (cell, row) => (
        <div className="d-flex">
          <TableButton
            Icon={Edit}
            onClick={() => {
              setProduct(row);
              setOpenModal(true);
            }}
          />
          <TableButton
            Icon={Trash2}
            onClick={() => removeProductsHandler(row.id)}
          />
        </div>
      ),
      headerStyle: (colum, colIndex) => {
        return { width: "12%" };
      },
      footer: () => null,
      editable: false,
    },
  ];

  const rowStyle = (row, rowIndex) => {
    const style = {};

    if (!row.nomenclature?.id) {
      style.backgroundColor = "#FF5C5C";
    }

    return style;
  };

  const rowClasses = (row, rowIndex) => {
    const foundCarriedOut = products.find((item) => item.carriedOut);

    if (!bailor && (foundCarriedOut || carriedOut) && !row.carriedOut) {
      return "error";
    }
  };

  return (
    <>
      {loading && <MySpinner overlay />}

      {!carriedOut && (
        <Card className="supply-product mb-0">
          <CardBody>
            {loading && <MySpinner overlay />}

            <Row className="supply-product__toolbar">
              <Col md="12" className="button-block">
                <Button
                  color="primary"
                  onClick={() => setOpenModal(true)}
                  className="button-wide"
                >
                  Добавить товар
                </Button>

                {selected.length > 0 && (
                  <div className="d-flex">
                    <Button
                      onClick={() => removeProductsHandler()}
                      color="danger"
                    >
                      <Trash2 size="15" /> Удалить
                    </Button>

                    <div style={{ width: "250px" }}>
                      <Select
                        name="warehouse"
                        placeholder="Склад"
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={warehouses.map((item) => {
                          return {
                            label: item.name,
                            value: item.id,
                          };
                        })}
                        value={warehouse}
                        onChange={setWarehouse}
                      />
                    </div>

                    <Button onClick={changeWarehouseHandler}>
                      Изменить склад
                    </Button>
                  </div>
                )}

                <>
                  <Select
                    placeholder="Импорт накладной"
                    className="react-select-container import"
                    classNamePrefix="react-select"
                    options={TEMPLATES}
                    onChange={importHandler}
                    value={template}
                    isSearchable={false}
                  />

                  <Input
                    type="file"
                    id="supply"
                    hidden
                    innerRef={inputSupplyFile}
                    onChange={(e) => setSupplyFile(e.target.files[0])}
                  />

                  <a
                    href="https://api.lider-truck.ru/files/templates/supplyTemplate.xls"
                    className="btn btn-secondary button-wide"
                  >
                    Скачать шаблон
                    <CornerRightDownIcon size={15} />
                  </a>
                </>
              </Col>
            </Row>
          </CardBody>
        </Card>
      )}
      <BootstrapTable
        wrapperClasses="supply-product"
        keyField="id"
        data={products}
        hover={true}
        columns={tableProductsColumns}
        bootstrap4
        bordered={false}
        cellEdit={cellEditFactory({
          mode: "click",
          blurToSave: true,
          afterSaveCell: (oldData, newData, row) =>
            productHandler(oldData, newData, row),
        })}
        selectRow={selectRow}
        rowStyle={rowStyle}
        rowClasses={rowClasses}
      />

      <AddProductModal
        supplyId={id}
        product={product}
        setProduct={setProduct}
        setCarriedOut={setCarriedOut}
        openModal={openModal}
        setOpenModal={setOpenModal}
        setProducts={setProducts}
        setLoading={setLoading}
        bailor={bailor}
        provider={provider}
        warehouses={warehouses}
      />

      <NomenclatureModal
        openModal={openNomenclatureModal}
        setOpenModal={setOpenNomenclatureModal}
        id={nomenclatureId}
        getAndSetNomenclature={getAndSetSupply}
      />
    </>
  );
};
