import React, { useState } from "react";
import { CustomerModal } from "../CustomerModal/CustomerModal";

import "./customerModalButton.scss";

export const CustomerModalButton = ({ order }) => {
  const [openModalCustomer, setOpenModalCustomer] = useState(false);
  return (
    <>
      <button
        onClick={() => setOpenModalCustomer(true)}
        className="orders-button_link"
      >
        <span className="cute">{order.customer.name}</span>
      </button>

      <CustomerModal
        customerId={order.customer.id}
        openModal={openModalCustomer}
        setOpenModal={setOpenModalCustomer}
      />
    </>
  );
};
