import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Status } from "components/Status";
import BootstrapTable from "react-bootstrap-table-next";
import { CheckCircle } from "react-feather";

import { Cut } from "components/Cut";

import { dateFormat, priceFormat, timeFormat } from "utils/format";
import { CustomerModalButton } from "../CustomerModalButton";

import "./ordersTable.scss";
import user from "assets/img/user.svg";
import { SOURCES } from "pages/orders";
import { cut } from "utils/cut";

export const OrdersTable = ({ orders, statusHandler, selected, setSelected, selectType }) => {
  const history = useHistory();
  const [ctrl, setCtrl] = useState(false);

  const tableColumns = [
    {
      dataField: "number",
      text: "№",
      formatter: (cell, row) => row.order.number,
      headerStyle: () => {
        return { width: "4%" };
      },
    },
    {
      dataField: "carriedOut",
      text: "Пров-но",
      formatter: (cell, row) =>
        row.order.carriedOut ? <CheckCircle color="green" size={17} /> : "",
      headerStyle: () => {
        return { width: "4%" };
      },
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "sentToDiadoc",
      text: "Диадок",
      formatter: (cell, row) =>
        row.order.sentToDiadoc ? <CheckCircle color="green" size={17} /> : "",
      headerStyle: () => {
        return { width: "4%" };
      },
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "utdIsSent",
      text: "УПД отпр",
      formatter: (cell, row) =>
        row.order.utdIsSent ? <CheckCircle color="green" size={17} /> : "",
      headerStyle: () => {
        return { width: "4%" };
      },
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "date",
      text: "Дата зак.",
      formatter: (cell, row) => <Cut><span>{dateFormat(row.order.date)}</span> <small>{timeFormat(row.order.date)}</small></Cut>,
      headerStyle: () => {
        return { width: "7%" };
      },
    },
    {
      dataField: "shippingDate",
      text: "Дата реал.",
      formatter: (cell, row) => dateFormat(row.order.shippingDate),
      headerStyle: () => {
        return { width: "5%" };
      },
    },
    {
      dataField: "dateDelivery",
      text: "Дата дост.",
      formatter: (cell, row) => dateFormat(row.delivery.date),
      headerStyle: () => {
        return { width: "5%" };
      },
    },
    {
      dataField: "customer",
      text: "Контрагент",
      headerStyle: () => {
        return { width: "16s%" };
      },
      classes: () => "bold",
      formatter: (cell, row) => <CustomerModalButton order={row} />,
      events: {
        onClick: (e) => {
          e.stopPropagation();
        },
      },
    },
    {
      dataField: "sum",
      text: "Сумма",
      headerStyle: () => {
        return { width: "4%" };
      },
      formatter: (cell, row) => priceFormat(row.order.sum),
      headerClasses: () => "right",
      classes: () => "right bold",
    },
    {
      dataField: "status",
      text: "Статус",
      headerStyle: () => {
        return { width: "8%" };
      },
      formatter: (cell, row) => (
        <Status order={row} statusHandler={statusHandler}>
          {row.status.name}
        </Status>
      ),
      events: {
        onClick: (e) => {
          e.stopPropagation();
        },
      },
    },
    {
      dataField: "payment",
      text: "Тип оплаты",
      formatter: (cell, row) => cut(row.order.payment, 6),
      headerStyle: () => {
        return { width: "5%" };
      },
    },
    {
      dataField: "source",
      text: "Источник",
      headerStyle: () => {
        return { width: "5%" };
      },
      formatter: (cell, row) => {
        const source = SOURCES.find((val) => val.value === row.order.source);
        return source?.label || "Менеджер";
      },
    },
    {
      dataField: "admin",
      text: "Менеджер",
      headerStyle: () => {
        return { width: "7%" };
      },
      formatter: (cell, row) => (
        <Cut>
          <span className="user-cell">
            <img src={user} alt="user" />
            {row.admin.name}
          </span>
        </Cut>
      ),
    },
    {
      dataField: "phone",
      text: "Телефон",
      formatter: (cell, row) => row.customer.phone,
      headerStyle: () => {
        return { width: "7%" };
      },
    },
    {
      dataField: "comment",
      text: "Комментарий",
      formatter: (cell, row) => <Cut>{row.order.comment}</Cut>,
      headerStyle: () => {
        return { width: "14%" };
      }
    },
  ];

  useEffect(() => {
    const onKeyDown = (e) => e.keyCode === 17 && setCtrl(true);
    const onKeyUp = (e) => e.keyCode === 17 && setCtrl(false);
    document.addEventListener("keydown", onKeyDown);
    document.addEventListener("keyup", onKeyUp);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("keyup", onKeyUp);
    };
  }, []);

  const rowEvents = {
    onClick: (e, row) => {
      if (ctrl) {
        window.open(`/sales/orders/${row.order.id}`, "_blank");
        return;
      }

      history.push(`/sales/orders/${row.order.id}`);
    },
  };

  const selectRow = {
    mode: selectType ?? "checkbox",
    bgColor: "#bfbfbf",
    selected,
    onSelect: (row, isSelect, rowIndex, e) => {
      if (selectType === "radio") {
        setSelected([row.order.id]);
        return;
      }
      setSelected((prev) => {
        if (!isSelect) {
          prev.splice(
            prev.findIndex((val) => val === row.order.id),
            1
          );
          return [...prev];
        }
        return [...prev, row.order.id];
      });
    },
    onSelectAll: (isSelect, rows, e) => {
      if (!isSelect) {
        setSelected([]);
      } else {
        setSelected(rows.map((val) => val.id));
      }
    },
  };

  return (
    <>
      <BootstrapTable
        keyField="id"
        data={orders}
        columns={tableColumns}
        bootstrap4
        bordered={false}
        hover={true}
        rowEvents={rowEvents}
        classes="pointer"
        selectRow={selectRow}
      />
    </>
  );
};
